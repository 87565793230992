import { FC, memo } from "react";

const CapitalCrimesDefenseProgramIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.686 3.64282C12.0854 3.99682 10.3909 5.04548 8.92037 5.97282C7.45011 6.90048 5.62502 7.91148 4.86535 8.21982C2.28785 9.26582 2.16422 9.75382 3.93841 11.8711C4.60505 12.6668 5.15049 13.5335 5.15049 13.7975C5.15049 14.3801 6.81498 14.8038 11.263 15.3525C15.8647 15.9202 24.2408 14.9345 24.2408 13.8251C24.2408 13.5765 24.7862 12.6798 25.4529 11.8325C26.1195 10.9851 26.6628 10.1885 26.6604 10.0621C26.6489 9.49015 26.0726 9.03215 24.2408 8.13848C23.1575 7.61015 20.7782 6.23748 18.9534 5.08848C15.3344 2.80948 14.4469 2.60448 12.686 3.64282ZM12.7603 8.54348C12.1833 8.98815 12.0842 9.29415 12.2197 10.2135C12.8002 14.1488 15.9929 14.3788 17.0328 10.5601C17.3616 9.35282 17.3353 9.22082 16.6468 8.63882C15.7235 7.85815 13.7139 7.80882 12.7603 8.54348ZM5.45502 17.2491C5.45896 17.8911 7.87919 20.8922 9.19188 21.8828C13.6517 25.2485 19.6494 23.9365 22.8587 18.8935C23.4472 17.9685 23.8911 17.1701 23.8451 17.1195C23.799 17.0688 22.5396 17.2451 21.0467 17.5118C17.5992 18.1275 11.1715 18.1185 8.02919 17.4941C6.6959 17.2291 5.57078 17.0091 5.52926 17.0058C5.48775 17.0021 5.45412 17.1115 5.45502 17.2491ZM5.15049 21.3651C5.15049 21.9941 4.87777 23.0688 4.54445 23.7528C3.42903 26.0418 3.85144 28.0478 5.61957 28.8608C6.61832 29.3198 8.48372 29.5115 8.48372 29.1552C8.48372 29.0575 8.28615 28.4575 8.04464 27.8218C7.70617 26.9312 7.4283 26.6658 6.83256 26.6658C6.25015 26.6658 6.05955 26.4938 6.05955 25.9678C6.05955 25.5505 6.30288 25.2002 6.66559 25.0958C7.66496 24.8082 7.43739 23.0705 6.19833 21.5268L5.15049 20.2215V21.3651ZM22.8015 21.5062C21.6109 22.8862 21.4088 24.8042 22.4227 25.0958C22.7854 25.2002 23.0287 25.5505 23.0287 25.9678C23.0287 26.4938 22.8381 26.6658 22.2557 26.6658C21.66 26.6658 21.3821 26.9312 21.0436 27.8218C20.4652 29.3445 20.4615 29.3325 21.5233 29.3325C22.7851 29.3325 24.4275 28.5275 24.8226 27.7158C25.2993 26.7358 25.2232 24.9292 24.6681 24.0578C24.4032 23.6415 24.0966 22.6405 23.9863 21.8328L23.7863 20.3648L22.8015 21.5062Z"
          fill="black"
        />
      </svg>
    );
  }
);

export default CapitalCrimesDefenseProgramIcon;
