import { MutableRefObject, useEffect, useRef, useState } from "react";
import { FacebookF, Linkedin, Twitter } from "styled-icons/fa-brands";
import { ChevronUp } from "styled-icons/boxicons-regular";
import FooterMountainsGraphic from "./FooterMountainsGraphic";
import SCOLogo from "../../styles/images/SCOFooterLogo.png";
import BrandonPillGraphic from "../../styles/images/BrandonPillGraphic.png";
import { FOOTER_CONSTANTS, URL_CONSTANTS } from "../../types/constants";
import "./footer.scss";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const scrollRef: MutableRefObject<number> = useRef<number>(0);
  const [scrollButtonFixed, setScrollButtonFixed] = useState<boolean>(false);
  const footerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const updateScroll = () => {
      const topOfPageThickness = 50;
      const canSeeFooterButton =
        (window.visualViewport?.height ?? 0) >
        footerRef.current!.getBoundingClientRect().top + 180;

      const shouldBeFixed =
        window.scrollY < scrollRef.current &&
        window.scrollY > topOfPageThickness &&
        !canSeeFooterButton;
      setScrollButtonFixed(shouldBeFixed);
      scrollRef.current = window.scrollY;
    };
    window.addEventListener("scroll", updateScroll);
    return () => window.removeEventListener("scroll", updateScroll);
  }, []);

  return (
    <>
      <div className="footer-container" ref={footerRef}>
        <button
          className={
            scrollButtonFixed
              ? "scroll-button-fixed scroll-button"
              : "scroll-button"
          }
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <ChevronUp size="70px" color="#CED0D1" />
        </button>

        <div className="footer-content">
          <div className="brandon-pill">
            <a
              href="https://www.sco.idaho.gov/LivePages/brandon-woolf-bio.aspx"
              target={"_blank"}
              className="footer-link"
            >
              <img
                className="brandon-pill-graphic"
                src={BrandonPillGraphic}
                alt="SCOLogo"
              />
              <div className="brandon-pill-text">
                <p>{FOOTER_CONSTANTS.brandonPill.TRANSPARENT_IDAHO}</p>
                <img className="sco-logo" src={SCOLogo} alt="" />
              </div>
            </a>
          </div>

          <div className="footer-right">
            <div className="address-container">
              <h2>
                {FOOTER_CONSTANTS.stateControllersOffice.STATE_CONTROLLER}
              </h2>
              <p>{FOOTER_CONSTANTS.stateControllersOffice.ADDRESS_LINE_1}</p>
              <p>{FOOTER_CONSTANTS.stateControllersOffice.ADDRESS_LINE_2}</p>
              <p>{FOOTER_CONSTANTS.stateControllersOffice.ADDRESS_LINE_3}</p>
            </div>

            <div className="socials">
              <button className="social-button">
                <a
                  href="https://www.facebook.com/IdahoController"
                  target={"_blank"}
                >
                  <FacebookF size="30px" color="#CED0D1" />
                </a>
              </button>
              <button className="social-button">
                <a href="https://twitter.com/IdahoController" target={"_blank"}>
                  <Twitter size="30px" color="#CED0D1" />
                </a>
              </button>
              <button className="social-button">
                <a
                  href="https://www.linkedin.com/company/office-of-the-state-controller-of-idaho/"
                  target={"_blank"}
                >
                  <Linkedin size="30px" color="#CED0D1" />
                </a>
              </button>
            </div>
          </div>
        </div>
        <FooterMountainsGraphic />
      </div>

      <div className="footer-bottom-bar app-padding">
        <div className="links">
          <a
            href="https://www.sco.idaho.gov/LivePages/site-policies.aspx"
            target={"_blank"}
            className="body-regular footer-link"
          >
            {FOOTER_CONSTANTS.footerBottom.SITE_POLICIES}
          </a>

          <p className="body-regular">
            {FOOTER_CONSTANTS.footerBottom.COPYRIGHT}
          </p>
        </div>

        <a
          href={URL_CONSTANTS.PUBLIC_RECORD_REQUEST}
          className="public-button floating-button"
          style={{ textDecoration: "none" }}
        >
          {FOOTER_CONSTANTS.footerBottom.PUBLIC_RECORDS}
        </a>
        <a
          href="mailto:transparentidaho@sco.idaho.gov"
          className="contact-button floating-button"
          style={{ textDecoration: "none" }}
          target={"_blank"}
        >
          {FOOTER_CONSTANTS.footerBottom.CONTACT_US}
        </a>
      </div>
    </>
  );
};

export default Footer;
