import { FC, memo } from "react";

const SquareMilesIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="34"
      height="31"
      viewBox="0 0 34 31"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1622_22764)">
        <path
          d="M29.0909 18.6668H32V21.5112H29.0909V18.6668ZM29.0909 12.9779H32V15.8223H29.0909V12.9779ZM32 24.3557H29.0909V27.2001C30.5455 27.2001 32 25.7779 32 24.3557ZM17.4545 1.6001H20.3636V4.44454H17.4545V1.6001ZM29.0909 7.28899H32V10.1334H29.0909V7.28899ZM29.0909 1.6001V4.44454H32C32 3.02232 30.5455 1.6001 29.0909 1.6001ZM0 7.28899H2.90909V10.1334H0V7.28899ZM23.2727 1.6001H26.1818V4.44454H23.2727V1.6001ZM23.2727 24.3557H26.1818V27.2001H23.2727V24.3557ZM2.90909 1.6001C1.45455 1.6001 0 3.02232 0 4.44454H2.90909V1.6001ZM11.6364 1.6001H14.5455V4.44454H11.6364V1.6001ZM5.81818 1.6001H8.72727V4.44454H5.81818V1.6001ZM0 12.9779V24.3557C0 25.9201 1.30909 27.2001 2.90909 27.2001H20.3636V12.9779H0ZM2.90909 24.3557L6.54545 19.7903L9.14909 22.8481L12.7855 18.2685L17.4545 24.3557H2.90909Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1622_22764"
          x="-2"
          y="0.600098"
          width="36"
          height="29.6001"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1622_22764"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1622_22764"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
});
export default SquareMilesIcon;
