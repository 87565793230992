import { FC, memo } from "react";

const ClipboardIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="22"
      height="29"
      viewBox="0 0 22 29"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M20 3.66602H17V2.66602C17 2.13558 16.7893 1.62687 16.4142 1.2518C16.0391 0.876729 15.5304 0.666016 15 0.666016H7C6.46957 0.666016 5.96086 0.876729 5.58579 1.2518C5.21071 1.62687 5 2.13558 5 2.66602V3.66602H2C1.46957 3.66602 0.960859 3.87673 0.585786 4.2518C0.210714 4.62687 0 5.13558 0 5.66602V26.666C0 27.1964 0.210714 27.7052 0.585786 28.0802C0.960859 28.4553 1.46957 28.666 2 28.666H20C20.5304 28.666 21.0391 28.4553 21.4142 28.0802C21.7893 27.7052 22 27.1964 22 26.666V5.66602C22 5.13558 21.7893 4.62687 21.4142 4.2518C21.0391 3.87673 20.5304 3.66602 20 3.66602ZM7 2.66602H15V6.66602H7V2.66602ZM20 26.666H2V5.66602H5V8.66602H17V5.66602H20V26.666Z"
        fill="#404E65"
      />
    </svg>
  );
});

export default ClipboardIcon;
