import { FC, useState } from "react";
import "./transparentSearchBar.scss";

type TransparentSearchBarProps = {
  version?: number;
  top?: string;
  mobile?: boolean;
};

const TransparentSearchBar: FC<TransparentSearchBarProps> = ({
  version,
  top,
  mobile = false,
}) => {
  const defaultClassName = "transparent-search-bar"
  const mobileClassName = "transparent-search-bar mobile"
  const [className, setClassName] = useState<string>(mobile ? mobileClassName + ' hide-iframe' : defaultClassName + ' hide-iframe');

  const handleClassName = (isHidden: boolean) => {
   const classType = mobile ? mobileClassName : defaultClassName 
      isHidden
      ? setClassName("hide-iframe " + classType)
      : setClassName(classType);
  };

  return (
    <iframe
      className={className}
      style={{ top: top }}
      scrolling="no"
      onMouseEnter={() => handleClassName(false)}
      onMouseLeave={() => handleClassName(true)}
      src={
        version
          ? `https://transparencyresources.idaho.gov/customsearch/${version}`
          : "https://transparencyresources.idaho.gov/customsearch"
      }
    />
  );
};

export default TransparentSearchBar;
