import { FC, memo } from "react";

const IntermodalCommerceAuthorityIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.18261 4.13153C5.3381 5.06053 5.15234 5.5172 5.15234 6.66486C5.15234 7.82053 5.33749 8.26853 6.21292 9.23153L7.27349 10.3982V15.9982V21.5982L6.21292 22.7649C5.33749 23.7279 5.15234 24.1759 5.15234 25.3315C5.15234 26.4792 5.3381 26.9359 6.18261 27.8649C7.02713 28.7939 7.44227 28.9982 8.48557 28.9982C9.52887 28.9982 9.94401 28.7939 10.7885 27.8649C11.633 26.9359 11.8188 26.4792 11.8188 25.3315C11.8188 24.1722 11.634 23.7282 10.7431 22.7482C9.81129 21.7232 9.67948 21.3832 9.75826 20.2089C9.86856 18.5649 10.8073 17.7519 13.8803 16.6395C16.9035 15.5452 19.1786 14.1899 20.254 12.8425C20.7809 12.1822 21.2124 11.5039 21.2124 11.3355C21.2124 11.1672 21.8261 10.3639 22.576 9.55053C23.7633 8.26286 23.9396 7.8902 23.9396 6.6682C23.9396 5.51653 23.7548 5.06153 22.9094 4.13153C21.4479 2.52386 19.7649 2.52386 18.3034 4.13153C17.5183 4.9952 17.2732 5.54687 17.2732 6.4492C17.2732 7.6032 17.7968 9.32086 18.1553 9.3422C18.2534 9.34786 18.5913 9.5672 18.9065 9.82953C19.4452 10.2782 19.4495 10.3559 18.9792 11.1455C18.3804 12.1505 17.4359 12.7152 13.6369 14.3389C12.0536 15.0155 10.5194 15.6815 10.2279 15.8192C9.74675 16.0465 9.69766 15.8075 9.69766 13.2339C9.69766 10.4375 9.7125 10.3819 10.7582 9.23153C11.6337 8.26853 11.8188 7.82053 11.8188 6.66486C11.8188 5.5172 11.633 5.06053 10.7885 4.13153C9.32706 2.52386 7.64408 2.52386 6.18261 4.13153Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default IntermodalCommerceAuthorityIcon;
