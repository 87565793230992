import { FC, memo } from "react";

const RevenueIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9997 22.7668V16.5H39.6797L23.9997 0.82L8.31969 16.5H17.9997L17.9997 32.5H23.6078C23.5675 32.1534 23.5468 31.8009 23.5468 31.4435C23.5468 27.3468 26.267 23.8853 29.9997 22.7668Z"
      />
      <path d="M33.4023 33.5391C31.0609 32.8477 30.308 32.1328 30.308 31.0195C30.308 29.7422 31.3497 28.8516 33.0928 28.8516C34.9288 28.8516 35.6095 29.8477 35.6714 31.3125H37.9509C37.8787 29.2969 36.7957 27.4453 34.64 26.8477V24.2812H31.5457V26.8125C29.5447 27.3047 27.9357 28.7813 27.9357 31.043C27.9357 33.75 29.9057 35.0977 32.7834 35.8828C35.362 36.5859 35.8777 37.6172 35.8777 38.707C35.8777 39.5156 35.3723 40.8047 33.0928 40.8047C30.9681 40.8047 30.1326 39.7266 30.0192 38.3438H27.75C27.8738 40.9102 29.5653 42.3516 31.5457 42.832V45.375H34.64V42.8555C36.6513 42.4219 38.25 41.0977 38.25 38.6953C38.25 35.3672 35.7436 34.2305 33.4023 33.5391Z" />
    </svg>
  );
});
export default RevenueIcon;
