import { FC, memo } from "react";

const WaterConservationIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5735 3.23362C12.0735 3.53095 11.3029 4.19995 10.8611 4.72028C10.4193 5.24028 9.86505 5.66595 9.62991 5.66595C9.39446 5.66595 8.80145 6.12595 8.31207 6.68795C7.57422 7.53528 7.4221 7.98962 7.4221 9.34795C7.4221 10.7536 7.5627 11.1413 8.41207 12.0763L9.40173 13.1659L14.2822 13.2729C19.6832 13.3909 20.6556 13.1666 21.3753 11.6356C22.2304 9.81628 21.6111 7.73462 19.9956 6.99862C19.4223 6.73728 18.8251 6.07162 18.4624 5.28928C17.4088 3.01762 14.5904 2.03362 12.5735 3.23362ZM7.65633 16.1839C7.05272 17.5766 7.3818 18.4993 8.48267 18.4993C9.58355 18.4993 9.91263 17.5766 9.30901 16.1839C9.02659 15.5323 8.65479 14.9993 8.48267 14.9993C8.31056 14.9993 7.93875 15.5323 7.65633 16.1839ZM19.7772 16.1839C19.1736 17.5766 19.5026 18.4993 20.6035 18.4993C21.7044 18.4993 22.0335 17.5766 21.4298 16.1839C21.1474 15.5323 20.7756 14.9993 20.6035 14.9993C20.4314 14.9993 20.0596 15.5323 19.7772 16.1839ZM13.7168 18.8506C13.1131 20.2433 13.4422 21.1659 14.5431 21.1659C15.644 21.1659 15.973 20.2433 15.3694 18.8506C15.087 18.1989 14.7152 17.6659 14.5431 17.6659C14.371 17.6659 13.9992 18.1989 13.7168 18.8506ZM3.13496 19.3303L2.48438 19.6193L3.52343 23.3926C4.29856 26.2063 4.79309 27.4203 5.46792 28.1659L6.37304 29.1659H14.5431H22.7131L23.6183 28.1659C24.2931 27.4203 24.7876 26.2066 25.5621 23.3946L26.6009 19.6229L25.8552 19.3113C24.5807 18.7783 24.3037 18.9496 23.7998 20.5826C23.0634 22.9683 22.1004 23.9993 20.6084 23.9993C19.6487 23.9993 18.2481 23.0963 18.0287 22.3363C17.7812 21.4779 17.3654 21.4779 17.1179 22.3363C16.8988 23.0946 15.4985 23.9993 14.5431 23.9993C13.5877 23.9993 12.1874 23.0946 11.9683 22.3363C11.862 21.9676 11.6571 21.6659 11.5129 21.6659C11.3686 21.6659 11.1638 21.9676 11.0574 22.3363C10.8384 23.0946 9.4381 23.9993 8.48267 23.9993C7.06484 23.9993 6.11275 23.0643 5.42853 21.0003C4.74552 18.9403 4.46856 18.7386 3.13496 19.3303Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default WaterConservationIcon;
