import { FC, memo } from "react";

const AquiferRechargeDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4384 4.06572L8.48391 5.40738V9.36972V13.3324H14.5443H20.6047V9.36738V5.40238L17.8018 4.16338C16.2603 3.48172 14.8625 2.87905 14.6958 2.82405C14.5292 2.76905 13.0632 3.32772 11.4384 4.06572ZM8.91511 17.0824C8.45663 18.4114 7.35394 21.5944 6.46458 24.1554C5.57521 26.7161 4.84766 28.9287 4.84766 29.0721C4.84766 29.2151 5.33764 29.3324 5.93671 29.3324C6.77578 29.3324 7.06244 29.1774 7.18637 28.656C7.32546 28.0704 17.0385 20.3324 18.3057 19.7974C18.5978 19.674 18.9151 20.1664 19.333 21.3924C19.6654 22.3677 19.8902 23.2204 19.8323 23.287C19.7748 23.3537 19.3611 23.0914 18.9133 22.7037C17.9024 21.829 17.7336 21.829 16.6158 22.7047L15.7152 23.4101L18.6269 25.552C20.3681 26.8327 21.6523 28.023 21.8217 28.513C22.0486 29.1694 22.3174 29.3324 23.1728 29.3324C23.7604 29.3324 24.241 29.2151 24.241 29.0721C24.241 28.9287 23.5134 26.7161 22.6241 24.1554C21.7347 21.5944 20.632 18.4114 20.1735 17.0824L19.3396 14.6657H18.1697H16.9994L17.4406 15.8274L17.8818 16.989L16.2176 18.2047L14.5531 19.4204L12.8953 18.1644L11.2375 16.908L11.6632 15.787L12.0892 14.6657H10.919H9.74902L8.91511 17.0824ZM9.73356 21.3847C9.3763 22.33 9.14055 23.1657 9.20964 23.2417C9.35449 23.4014 12.4095 21.255 12.4177 20.9877C12.4232 20.8087 10.785 19.6657 10.5229 19.6657C10.446 19.6657 10.0905 20.4394 9.73356 21.3847Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default AquiferRechargeDistrictIcon;
