import { ChevronRightCircle } from "styled-icons/boxicons-solid";
import { HOME_PAGE_CONSTANTS, NAV_TAGS } from "../../types/constants";
import "./verticalCard.scss";

type CardProps = {
  cardTitle: string;
  cardDescription: string;
  cardTag?: string;
  comingSoon?: boolean;
  image?: string;
  readMoreText?: string;
  readMoreAction?: () => void;
  noReadMoreText?: boolean;
};

const VerticalCard = ({
  cardTitle,
  cardDescription,
  cardTag = "",
  comingSoon = false,
  image,
  readMoreText,
  noReadMoreText = false,
  readMoreAction,
}: CardProps) => {
  const getCardTagLabel = () => {
    switch (cardTag) {
      case NAV_TAGS.LOCAL_DISTRICT:
        return "Local District";
      case NAV_TAGS.COUNTY:
        return "COUNTY";
      case NAV_TAGS.WORKFORCE_SALARY:
        return "Workforce & Salary";
      default:
        return cardTag;
    }
  };

  const handleImageClass = () => {
    return comingSoon ? 'coming-soon-img img-container' : "img-container"
  }

  return (
    <div id="card" className="card-container" onClick={readMoreAction}>
      <div
        className={handleImageClass()}
      >
        {comingSoon && (
          <p className="tag-coming body-small">{NAV_TAGS.COMING_SOON}</p>
        )}
      <img
        src={image || "https://via.placeholder.com/452x239"}
        className={"card-image"}
        alt=""
        />
      </div>
      <div className="tag-container">
        <h6
          className={`tag-name body-small tag-${cardTag.replace(/[&\s]/g, "")}`}
        >
          {getCardTagLabel()}
        </h6>      
      </div>
      <h6 className="vertical-card-title">{cardTitle}</h6>
      <p className="vertical-card-description body-medium">{cardDescription}</p>
      <div className="button-readmore-container">
        {
          !noReadMoreText && !comingSoon && (
            <>
              <p className="button-readmore-text">
                {readMoreText ? readMoreText : HOME_PAGE_CONSTANTS.buttons.READ_MORE}
              </p>
            <ChevronRightCircle
              className="readmore-icon"
              size="20px"
              color="#404E65"
            />
          </>
        )
      }
      </div>
    </div>
  );
};

export default VerticalCard;
