import PowerBi from "../powerBi/PowerBi";
import DownloadDataInformational from "../reportSection/DownloadData";

const CountyCompareData = () => {
  return (
    <div className="landing-page-reports-container app-padding">
      <h4 className="landing-page-high-level-header">COMPARE DATA</h4>
      <p className="landing-page-high-level-subheader body-large">
        The County Comparison Feature is a tool facilitating the examination of
        financial data across counties. This tool allows citizens to conduct a
        side-by-side analysis of two counties or select multiple counties for a
        comprehensive year-over-year comparison. Please note that this report
        displays "$0" for values under $1 million for enhanced data
        visualization clarity.
      </p>
      <br />
      <PowerBi reportId={"57279f66-3ea9-4041-9770-20b4e128cc3f"} fullScreen />
      <DownloadDataInformational />
    </div>
  );
};

export default CountyCompareData;
