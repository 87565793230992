import { FC, memo } from "react";

const GrazingDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.4862 5.48833C8.4862 6.66067 8.35075 7.05633 7.84773 7.35233C7.3023 7.67367 7.06897 7.57867 6.24536 6.70067L5.28175 5.67333L4.6142 6.40733L3.94695 7.14167L4.88086 8.20167C5.67901 9.10767 5.76537 9.36433 5.47326 9.96433C5.20418 10.5177 4.84449 10.6667 3.77877 10.6667H2.42578V12V13.3333H3.77877C4.83995 13.3333 5.20448 13.4833 5.46993 14.0287C5.65598 14.411 5.76295 14.763 5.7078 14.8107C5.65265 14.8587 4.89176 15.3197 4.01664 15.8353L2.42578 16.7727V21.212V25.6517L4.01664 23.9723C9.53768 18.1447 16.3644 14.1623 23.5615 12.571L26.6674 11.8843V10.625V9.36567L24.016 9.546C21.7364 9.70067 19.5886 10.0297 15.2905 10.882C14.5015 11.0387 14.313 10.9323 13.9881 10.1483C13.64 9.30767 13.6848 9.145 14.529 8.18667L15.4496 7.14167L14.7824 6.40733L14.1148 5.67333L13.1512 6.70067C12.3276 7.57867 12.0943 7.67367 11.5488 7.35233C11.0458 7.05633 10.9104 6.66067 10.9104 5.48833V4H9.69828H8.4862V5.48833ZM7.88016 10C7.22563 10.72 7.02382 13.124 7.58592 13.506C7.75743 13.6227 8.84831 13.3077 10.0101 12.8063C12.3085 11.8143 12.6267 11.2213 11.5164 10C11.1125 9.55567 10.5064 9.33333 9.69828 9.33333C8.89012 9.33333 8.28408 9.55567 7.88016 10ZM23.9403 15.518C17.6511 17.0023 10.4237 21.3083 6.05658 26.1727L4.41602 28H7.85531H11.2946L13.496 25.6993C15.622 23.478 19.0159 20.748 21.2131 19.4917C21.7964 19.1583 23.2282 18.5513 24.3948 18.1427C26.465 17.418 26.5181 17.3713 26.6099 16.2C26.7171 14.832 26.779 14.848 23.9403 15.518ZM22.8797 22.1517C21.3797 22.967 19.0616 24.579 17.7283 25.7337L15.3042 27.8333L19.3949 27.9353C24.4184 28.0607 25.6329 27.7447 26.2366 26.1563C26.4735 25.532 26.6674 24.0417 26.6674 22.844C26.6674 20.1493 26.589 20.135 22.8797 22.1517Z"
        fill="black"
      />
    </svg>
  );
});
export default GrazingDistrictIcon;
