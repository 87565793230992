import { FC, memo } from "react";

const FireDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="24"
      height="32"
      viewBox="0 0 24 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2254 16.9389C20.8698 10.5716 12.4829 10.2283 14.5083 0.97389C14.6584 0.287224 13.9532 -0.243381 13.3831 0.115558C7.93683 3.45525 4.02093 10.1502 7.30669 18.9208C7.57675 19.6387 6.76656 20.3098 6.18143 19.8416C3.4658 17.7036 3.18073 14.6292 3.42079 12.4287C3.51081 11.6172 2.49057 11.2271 2.05547 11.8981C1.03524 13.5211 0 16.143 0 20.0913C0.570132 28.8307 7.66677 31.5149 10.2174 31.8583C13.8632 32.342 17.8091 31.6398 20.6448 28.9399C23.7655 25.928 24.9057 21.1213 23.2254 16.9389ZM9.30215 24.7887C11.4626 24.2425 12.5729 22.6195 12.873 21.1837C13.3681 18.9521 11.4326 16.7672 12.7379 13.2402C13.2331 16.1586 17.6441 17.9845 17.6441 21.1681C17.7641 25.1164 13.6532 28.5029 9.30215 24.7887Z"
        fill="black"
      />
    </svg>
  );
});
export default FireDistrictIcon;
