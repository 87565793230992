import { FC, memo } from "react";

const AirportIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73989 4.76435L7.93355 5.16602L10.1923 9.47102C11.435 11.839 12.3922 13.9453 12.3201 14.1523C12.248 14.359 10.8219 14.8833 9.15078 15.3173L6.113 16.1063L5.15485 15.2193C4.22033 14.3547 3.214 14.0713 2.92189 14.5913C2.81098 14.7883 5.08788 19.3043 5.62453 19.9527C5.79907 20.1633 25.5321 14.3437 25.9563 13.9567C26.4621 13.4947 26.489 11.937 26.0003 11.3994C25.4688 10.8147 24.5885 10.899 21.0516 11.8737L17.879 12.7484L13.8225 8.54035C11.591 6.22635 9.71622 4.33935 9.65592 4.34768C9.59562 4.35602 9.18321 4.54335 8.73989 4.76435ZM3.63732 25.3327V26.666H14.5461H25.4548V25.3327V23.9993H14.5461H3.63732V25.3327Z"
        fill="black"
      />
    </svg>
  );
});
export default AirportIcon;
