import { EntityFilter } from "../../types/interfaces/entityInterfaces";
import { ENTITY_TYPE_IDS } from "../../types/constants/entityTypeIds";
import { requestEntityTypeInCountyList } from "../../api/searchType.api";
import { CountyIcon } from "../../styles/icons";

export const getEntityTypeInCountyList = async (
  entityTypeID: string | number
) => {
  const data = await requestEntityTypeInCountyList(entityTypeID);

  //eslint-disable-next-line
  const entitiesData: EntityFilter[] = data.map((entity: any) => {
    const item: EntityFilter = {
      id: entity.CountyID,
      name: entity.CountyName,
      hasThisEntity: entity.EntityTypeInCounty,
      type: ENTITY_TYPE_IDS.COUNTY,
      Icon: CountyIcon,
    };
    return item;
  });
  entitiesData.unshift({
    id: 0,
    name: "All Counties",
    Icon: CountyIcon,
    hasThisEntity: "TRUE",
  });
  return entitiesData;
};
