import { FC, memo } from "react";

const DollarBillIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="34"
      height="24"
      viewBox="0 0 34 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 14.25C18 14.25 16.9375 13.8125 16.0625 12.9375C15.1875 12.0625 14.75 11 14.75 9.75C14.75 8.5 15.1875 7.4375 16.0625 6.5625C16.9375 5.6875 18 5.25 19.25 5.25C20.5 5.25 21.5625 5.6875 22.4375 6.5625C23.3125 7.4375 23.75 8.5 23.75 9.75C23.75 11 23.3125 12.0625 22.4375 12.9375C21.5625 13.8125 20.5 14.25 19.25 14.25ZM7.25 19.5C6.63125 19.5 6.10156 19.2797 5.66094 18.8391C5.22031 18.3984 5 17.8688 5 17.25V2.25C5 1.63125 5.22031 1.10156 5.66094 0.660938C6.10156 0.220313 6.63125 0 7.25 0H31.25C31.8688 0 32.3984 0.220313 32.8391 0.660938C33.2797 1.10156 33.5 1.63125 33.5 2.25V17.25C33.5 17.8688 33.2797 18.3984 32.8391 18.8391C32.3984 19.2797 31.8688 19.5 31.25 19.5H7.25ZM11 17.25H27.5C27.5 16.2 27.8625 15.3125 28.5875 14.5875C29.3125 13.8625 30.2 13.5 31.25 13.5V6C30.2 6 29.3125 5.6375 28.5875 4.9125C27.8625 4.1875 27.5 3.3 27.5 2.25H11C11 3.3 10.6375 4.1875 9.9125 4.9125C9.1875 5.6375 8.3 6 7.25 6V13.5C8.3 13.5 9.1875 13.8625 9.9125 14.5875C10.6375 15.3125 11 16.2 11 17.25ZM29 24H2.75C2.13125 24 1.60156 23.7797 1.16094 23.3391C0.720313 22.8984 0.5 22.3688 0.5 21.75V4.5H2.75V21.75H29V24Z"
        fill="#currentColor"
      />
    </svg>
  );
});
export default DollarBillIcon;
