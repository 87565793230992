import { FC, memo } from "react";

const FolderIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M22.1538 5.02966H12.3819L9.23077 1.30557C9.05996 1.10209 8.85663 0.940763 8.6326 0.830971C8.40856 0.721179 8.1683 0.665111 7.92577 0.666027H1.84615C1.35652 0.666027 0.886947 0.895896 0.540726 1.30507C0.194505 1.71424 0 2.26919 0 2.84784V22.5687C0.000610604 23.1248 0.187774 23.6578 0.520447 24.0509C0.85312 24.4441 1.30415 24.6653 1.77462 24.666H22.2565C22.7187 24.6653 23.1618 24.448 23.4887 24.0617C23.8155 23.6755 23.9994 23.1518 24 22.6056V7.21148C24 6.63282 23.8055 6.07787 23.4593 5.6687C23.1131 5.25953 22.6435 5.02966 22.1538 5.02966ZM1.84615 2.84784H7.92577L9.77192 5.02966H1.84615V2.84784ZM22.1538 22.4842H1.84615V7.21148H22.1538V22.4842Z"
        fill="currentColor"
      />
    </svg>
  );
});
export default FolderIcon;
