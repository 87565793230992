import { FC, memo } from "react";

const BudgetIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.68955 37.462L4.68955 30.542C4.13755 29.59 4.46755 28.36 5.41955 27.81L8.16355 26.224C7.94782 24.7492 7.94782 23.2508 8.16355 21.776L5.41955 20.19C4.96216 19.9229 4.6288 19.486 4.49208 18.9743C4.35535 18.4626 4.42633 17.9176 4.68955 17.458L8.68955 10.538C8.82085 10.3105 8.99572 10.1112 9.20414 9.95134C9.41256 9.79152 9.65045 9.67435 9.90419 9.60655C10.1579 9.53874 10.4226 9.52164 10.6829 9.55621C10.9433 9.59077 11.1943 9.67634 11.4216 9.808L14.2115 11.42C15.3679 10.5098 16.643 9.76162 18.0015 9.196V6C18.0015 5.46957 18.2123 4.96086 18.5873 4.58579C18.9624 4.21071 19.4711 4 20.0015 4H28.0015C28.532 4 29.0407 4.21071 29.4158 4.58579C29.7908 4.96086 30.0015 5.46957 30.0015 6V9.196C31.3601 9.76043 32.6346 10.5088 33.7895 11.42L36.5816 9.808C37.0407 9.54263 37.5864 9.47046 38.0987 9.60735C38.611 9.74424 39.048 10.079 39.3135 10.538L43.3135 17.458C43.8655 18.41 43.5355 19.64 42.5835 20.19L41.8836 20.5946C40.5293 20.1596 39.0853 19.9247 37.5865 19.9247C35.418 19.9247 33.3643 20.4164 31.5309 21.2944C30.4196 18.2102 27.4644 16 24.0016 16C19.5896 16 16.0015 19.588 16.0015 24C16.0015 28.3062 19.4196 31.8275 23.6857 31.9939C23.5954 32.6371 23.5488 33.2943 23.5488 33.9624C23.5488 37.8958 25.1665 41.4514 27.773 44H20.0035C19.4731 44 18.9644 43.7893 18.5893 43.4142C18.2143 43.0391 18.0036 42.5304 18.0036 42V38.804C16.6556 38.244 15.3735 37.494 14.2135 36.58L11.4216 38.192C10.9624 38.4574 10.4167 38.5295 9.90441 38.3927C9.39209 38.2558 8.95511 37.921 8.68955 37.462Z"
      />
      <path d="M37.6144 33.484C34.7871 32.7418 33.8779 31.9745 33.8779 30.7796C33.8779 29.4085 35.1359 28.4525 37.2408 28.4525C39.4578 28.4525 40.2798 29.5217 40.3545 31.094H43.1071C43.0199 28.9305 41.7121 26.9431 39.109 26.3016V23.5469H35.3725V26.2639C32.9562 26.7922 31.0133 28.3771 31.0133 30.8047C31.0133 33.7104 33.3922 35.1569 36.8671 35.9997C39.9809 36.7544 40.6036 37.8613 40.6036 39.0312C40.6036 39.8991 39.9933 41.2827 37.2408 41.2827C34.675 41.2827 33.6662 40.1255 33.5292 38.6412H30.7891C30.9385 41.3959 32.9812 42.9431 35.3725 43.4588V46.1884H39.109V43.484C41.5378 43.0186 43.4683 41.5972 43.4683 39.0186C43.4683 35.4462 40.4417 34.2261 37.6144 33.484Z" />
    </svg>
  );
});

export default BudgetIcon;
