import { useEffect, useState, useRef, RefObject } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { models } from "powerbi-client";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import SomethingWentWrongPage from "../somethingWentWrongPage/SomethingWentWrongPage";
import {
  IdahoCountiesMap,
  InfoBubble,
  LoadingBar,
  LoadingState,
  ReportSection,
  StickyNav,
  VerticalCard,
  CountyCompareData,
} from "../../components";
import CountyEntityFinancialTable from "./countyPageComponents/CountyEntityFinancialTable";
import {
  RevenueIcon,
  ThreeDotCircleIcon,
  BudgetIcon,
  CompareIcon,
  DollarSignIcon,
  PopulationIcon,
  SquareMilesIcon,
} from "../../styles/icons";

import { County } from "../../types/interfaces/entityInterfaces";
import {
  API_CONSTANTS,
  COUNTY_SPECIFIC_EMBEDDED_REPORTS,
  ERROR_CONSTANTS,
  NAV_TAGS,
} from "../../types/constants";
import { getCountyById } from "./countyPage.service";
import "./countyPage.scss";

const CountyPage = () => {
  const { id } = useParams<{ id: string }>();
  const [showErrorPage, setShowErrorPage] = useState<boolean>(false);
  const [county, setCounty] = useState<County>();
  const [hoveredCounty, setHoveredCounty] = useState<string>("");

  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.Idle
  );
  const navigate = useNavigate();

  const exploreDataRef = useRef<HTMLDivElement>(null);

  const expendituresRef = useRef<HTMLDivElement>(null);
  const revenuesRef = useRef<HTMLDivElement>(null);
  const moreResourcesRef = useRef<HTMLDivElement>(null);
  const countyCompareDataRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingState(LoadingState.Starting);
    if (id) {
      handleGetCountyById(id);
    }
  }, [id]);

  const handleScroll = (refToScroll: RefObject<HTMLDivElement>) => {
    const stickyNavHeight =
      document.querySelector(".sticky-nav")?.clientHeight ?? 0;
    if (refToScroll?.current) {
      const refRect = refToScroll.current.getBoundingClientRect();
      const absoluteRefTop = refRect.top + window.scrollY;
      const scrollPosition = absoluteRefTop - stickyNavHeight;
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  const handleGetCountyById = async (id: string) => {
    const data = await getCountyById(id);
    if (data === ERROR_CONSTANTS.NOT_FOUND_ERROR) {
      navigate("/*");
    } else if (data === ERROR_CONSTANTS.SERVER_ERROR) {
      setShowErrorPage(true);
    } else {
      setCounty(data as County);
    }
    setLoadingState(LoadingState.Finished);
  };

  return showErrorPage ? (
    <SomethingWentWrongPage />
  ) : (
    <div className="county-page-container">
      {
        <LoadingBar
          loadingState={loadingState}
          setLoadingState={setLoadingState}
        />
      }
      <header>
        <Header />
      </header>
      <div className="county-hero-container">
        {county && (
          <img
            src={
              API_CONSTANTS.imageBucket +
              "/county-header-images/CountyHeader" +
              county?.id +
              ".jpg"
            }
            className="county-hero-background"
          />
        )}
        <div className="explore-section glass">
          <span className="county-hero-info">
            {county && (
              <img
                src={
                  API_CONSTANTS.imageBucket +
                  "/county-header-images/CountyHeader" +
                  county?.id +
                  ".jpg"
                }
                className="county-hero-background-mobile"
              />
            )}
            {county && (
              <img
                className="county-page-seal-image"
                src={
                  API_CONSTANTS.imageBucket +
                  "/county-seal-images/CountySeal" +
                  county?.id +
                  ".png"
                }
                alt=""
              />
            )}
            <span className="explore-info-text">
              <h2 className="county-header">
                {county?.name ? county.name + " County" : "Loading County..."}
              </h2>
              <h6 className="county-text">{county?.narratives.AboutSummary}</h6>
            </span>
          </span>
          <div className="landing-page-info-buttons info-bubble-row-county">
            <InfoBubble
              titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderCountySizeLight.svg`}
              infoDetailText={"Sq. Miles"}
              Icon={SquareMilesIcon}
              infoAmount={
                county
                  ? county?.sizeSqMiles
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : ""
              }
            />
            <InfoBubble
              titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderPopulationLight.svg`}
              infoDetailText={"Citizens"}
              Icon={PopulationIcon}
              infoAmount={
                county
                  ? county?.population
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : ""
              }
            />
            <InfoBubble
              titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderTotalBudgetLight.svg`}
              Icon={DollarSignIcon}
              infoAmount={
                county
                  ? county?.budget
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : ""
              }
              infoDetailText={"Dollars"}
            />
          </div>
        </div>
      </div>
      <StickyNav
        sections={[
          {
            icon: <BudgetIcon />,
            text: "Expenditures",
            ref: expendituresRef,
          },
          {
            icon: <RevenueIcon />,
            text: "Revenues",
            ref: revenuesRef,
          },
          {
            icon: <CompareIcon />,
            text: "Compare",
            ref: countyCompareDataRef,
          },
          {
            icon: <ThreeDotCircleIcon />,
            text: "More Resources",
            ref: moreResourcesRef,
          },
        ]}
      />
      <div className="about-individual-county-section" ref={exploreDataRef}>
        <div className="about-description-county-container">
          <h2 className="about-description-header">
            About {county?.name} County
          </h2>
          <p className="body-medium about-description-text">
            {county?.narratives.CountySubheading}
          </p>
          <p className="body-large landing-page-about-description-link">
            Data collected from Counties over multiple fiscal years.
          </p>
        </div>
        <div className="idaho-map-container">
          <IdahoCountiesMap
            setHoveredCounty={setHoveredCounty}
            hoveredCounty={hoveredCounty}
            activeCounty={county?.name}
          />
        </div>
      </div>
      <div className="county-page-reports-light">
        <div className="app-padding">
          <h4 className="county-page-report-header">
            {county?.name} COUNTY FISCAL DATA
          </h4>
          <p className="body-large county-page-report-subheader">
            Below you will find interactive reports for budgets, fund balances,
            salaries {"\n"}and more. See information next to reports for helpful
            descriptions and insights.
          </p>
          <p className="body-large county-page-report-subheader">
            To gain insights by comparing counties{" "}
            <Link to={""} onClick={() => handleScroll(countyCompareDataRef)}>
              click here.
            </Link>
          </p>
        </div>

        {county?.name && (
          <ReportSection
            scrollable
            title="Total County Budget"
            description={county?.narratives.TotalBudgetSummary}
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.CountyBudgetDetails.reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.CountyBudgetDetails.visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "County Budget",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {county?.name && (
          <ReportSection
            ref={expendituresRef}
            scrollable
            title="County Expenditures by Category"
            description={
              "Each county expends funds to provide an array of services to residents and businesses. Explore the interactive data to learn more about the county's expenditures by category over multiple fiscal years."
            }
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.CountyExpendituresByCategory
                .reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.CountyExpendituresByCategory
                .visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "County Budget",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}

        {county?.name && (
          <div ref={revenuesRef}>
            <ReportSection
              scrollable
              title="County Revenues by Category"
              description="Each county relies on a variety of revenue sources to fund services and facilities provided to its citizens. These revenue sources include but are not limited to, user fees, licensing/permitting, taxes, and federal funding. Explore the interactive data to learn more about the county's revenues by category over multiple fiscal years."
              reportId={
                COUNTY_SPECIFIC_EMBEDDED_REPORTS.CountyRevenuesByCategory
                  .reportId
              }
              visualName={
                COUNTY_SPECIFIC_EMBEDDED_REPORTS.CountyRevenuesByCategory
                  .visualName
              }
              slicerFilterState={{
                filters: [
                  {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                      table: "County Budget",
                      column: "County",
                    },
                    operator: "In",
                    values: [county?.name],
                    filterType: models.FilterType.Basic,
                  },
                ],
              }}
            />
          </div>
        )}
      </div>
      <div className="county-page-reports-blue">
        <div className="app-padding">
          <h4 className="county-page-report-header">
            {county?.name} COUNTY FUND BALANCES
          </h4>
          <p className="body-large county-page-report-subheader">
            A fund balance is the remaining “cash on hand” leftover at the end
            of the year that is dedicated {"\n"} to cover first quarter expenses
            of the subsequent year and to provide financial flexibility for{" "}
            {"\n"} unplanned expenditures. Below you will find different reports
            that help you explore and {"\n"} understand the county’s current
            fund balances.
          </p>
        </div>
        {county?.name && (
          <ReportSection
            scrollable
            title="Top County Fund Balances by Category"
            description={county?.narratives.TopFundBalance}
            blue
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.TopCountyFundBalancesByCategory
                .reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.TopCountyFundBalancesByCategory
                .visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "rep County_Fund_Balances",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {county?.name && (
          <ReportSection
            scrollable
            title="Total County Fund Balances by Classification"
            description={
              "Non-Spendable: Non-Spendable fund balances are assets that are never converted to cash and are inherently non-spendable either because of their form or because they must be maintained intact, pursuant to legal or contractual requirements including the principal of endowments or capital of a revolving loan fund. \n\n Restricted: Restricted fund balances are amounts that are subject to limitations imposed by creditors, grantors, contributors or law and regulations of other governments or through constitutional provisions or enabling legislation. \n\n Committed: Committed fund balances are funds with a self-imposed limitation made at the highest level of decision-making that requires formal action at the same level to remove. This would occur annually via a resolution approved by the Board of County Commissioners. \n\n Assigned: Assigned fund balances are where a limitation results from intended uses either by the government’s highest decision-making authority of their designated body or official in conjunction with the close of the fiscal year. \n\n  Unassigned: Unassigned fund balances are residual net resources -total fund balance in excess of the other classifications (surplus) or excess of the other classifications over total fund balance (deficit)."
            }
            blue
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS
                .AllCountyFundBalancesByClassification.reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS
                .AllCountyFundBalancesByClassification.visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "rep County_Fund_Balances",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {county?.name && (
          <ReportSection
            scrollable
            title="County Fund Balances Based on Percent of Budget"
            description={`Many citizens are interested in how fund balances impact their county’s budget. To learn more about the percentage of the total county budget that makes up fund balances, explore the interactive data. The information provided includes each county’s budget with the percentage of their budget made up of various fund balances over multiple fiscal years.`}
            blue
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS
                .CountyFundBalanceByPercentOfBudget.reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS
                .CountyFundBalanceByPercentOfBudget.visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "rep County_Budget",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}

        <div className="app-padding">
          <h4 className="county-page-report-header">COMPARE COUNTY DATA</h4>
          <p className="body-large county-page-report-subheader">
            {" "}
            Each chart below can provide deeper insights by comparing county
            {"\n"}
            expenditures and revenues. To perform a comparison, select multiple
            {"\n"}
            items from the drop-down menu provided in the report.
          </p>
        </div>
        {county?.name && (
          <ReportSection
            scrollable
            title="Compare County Budgets"
            description={`Explore the interactive data to see how your county compares to others in the state.`}
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.TotalCountyBudget.reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.TotalCountyBudget.visualName
            }
            blue
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "County Budget",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {county?.name && (
          <ReportSection
            scrollable
            title="Compare County Expenditures"
            description={`Each county expends funds to provide an array of services to residents and businesses. 

              Explore the interactive data to learn more about the county’s expenditures by category over multiple fiscal years.`}
            blue
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.ExpenditureComparison.reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.ExpenditureComparison.visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "County Budget",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {county?.name && (
          <ReportSection
            scrollable
            title="Compare County Revenues"
            description={``}
            blue
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.RevenueComparison.reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.RevenueComparison.visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "County Budget",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
        {county?.name && (
          <ReportSection
            scrollable
            title="Compare County Expenditures Per Capita"
            blue
            description={
              "Counties spend money to provide their residents and businesses with an array of services. These expenses are county wide, but can also be broken down by the average amount the county spends per person per year for the services they provide."
            }
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.ExpenditurePerCapita.reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.ExpenditurePerCapita.visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "CountyQuickFacts",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
      </div>
      <div className="county-page-reports-light">
        <div className="app-padding">
          <h4 className="county-page-report-header">
            {county?.name} COUNTY SALARIES
          </h4>
          <p className="body-large county-page-report-subheader">
            In this section you can explore positions and salaries {"\n"}for
            county employees in the state of Idaho.
          </p>
        </div>
        {county?.name && (
          <ReportSection
            scrollable
            title="County Salaries Report"
            description="This report includes position and salary information for county employees in the state of Idaho. Explore the interactive salary data to learn more about what each county expends to pay its employees. The information provided includes the salary amounts based on the most recent report, which is updated every quarter. The data was last updated as of October 1st, 2023."
            reportId={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.CountySalariesReport.reportId
            }
            visualName={
              COUNTY_SPECIFIC_EMBEDDED_REPORTS.CountySalariesReport.visualName
            }
            slicerFilterState={{
              filters: [
                {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "County_Salary_Staging",
                    column: "County",
                  },
                  operator: "In",
                  values: [county?.name],
                  filterType: models.FilterType.Basic,
                },
              ],
            }}
          />
        )}
      </div>

      <div className="county-search-entity-section app-padding">
        <span className="county-entity-search-headers">
          <h4 className="county-entity-search-header">
            SEARCH {county?.name} COUNTY DISTRICTS
          </h4>
          <p className="county-entity-search-subheader body-large">
            {
              "Use the search feature to find local districts that service the county. You can view each district’s approved budget totals and the previous year’s actual totals. Search cities to download applicable financial documents.\n\n You can learn more about Local Districts "
            }
            <Link
              className="local-district-here-link"
              to={"/localDistrict"}
              state={{ tag: NAV_TAGS.EXPLORE_LOCAL_DISTRICT_DATA }}
            >
              {"here."}
            </Link>
          </p>
        </span>
        {id && <CountyEntityFinancialTable countyId={id} />}
      </div>
      <span ref={countyCompareDataRef}>
        <CountyCompareData />
      </span>
      <div className="county-report-cards" ref={moreResourcesRef}>
        <div className="report-cards-container">
          <h4 className="report-cards-header">More Resources</h4>
          <h6 className="report-cards-subheader">
            Download Approved Budget and Financial Audit Files
          </h6>
          <div className="cards-container">
            <a
              className="county-anchor-tag"
              href={county?.budgetReport}
              target={county?.budgetReport ? "_blank" : ""}
            >
              <VerticalCard
                cardTitle={county?.name + " County Approved Budget"}
                cardDescription="Click to download a file of the county’s most recent approved budget"
                cardTag={NAV_TAGS.COUNTY}
                readMoreText={county?.budgetReport ? "Download" : "Unavailable"}
                image={
                  API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-1.png"
                }
              />
            </a>
            <a
              className="county-anchor-tag"
              href={county?.auditReport}
              target={county?.auditReport ? "_blank" : ""}
            >
              <VerticalCard
                cardTitle={county?.name + " County Financial Audit"}
                cardDescription="Click to download a file of the county’s most recent financial audit."
                cardTag={NAV_TAGS.COUNTY}
                readMoreText={county?.auditReport ? "Download" : "Unavailable"}
                image={
                  API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-2.png"
                }
              />
            </a>
            <a
              className="county-anchor-tag"
              href={county?.narratives.CountyURL}
              target={"_blank"}
            >
              <VerticalCard
                cardTitle={"Visit the " + county?.name + " County Website"}
                cardDescription="Click to visit the county’s website for more county specific information."
                cardTag={NAV_TAGS.COUNTY}
                readMoreText="Visit Site"
                image={
                  county
                    ? API_CONSTANTS.imageBucket +
                      `/county-images/CountyImage${county?.id}.jpg`
                    : ""
                }
              />
            </a>
          </div>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default CountyPage;
