import { FC, memo } from "react";

const StudentIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2510_31147)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75177 10.8217L13.9918 4.68841C14.7918 4.26174 15.7518 4.26174 16.5518 4.68841L29.2451 11.6084C29.6718 11.8484 29.9384 12.2884 29.9384 12.7817V21.3284C29.9384 22.0617 29.3384 22.6617 28.6051 22.6617C27.8718 22.6617 27.2718 22.0617 27.2718 21.3284V13.4484L16.5518 19.3017C15.7518 19.7417 14.7918 19.7417 13.9918 19.3017L2.75177 13.1684C1.83177 12.6617 1.83177 11.3284 2.75177 10.8217ZM5.93843 21.3151V17.5684L13.9918 21.9684C14.7918 22.4084 15.7518 22.4084 16.5518 21.9684L24.6051 17.5684V21.3151C24.6051 22.2884 24.0718 23.1951 23.2184 23.6617L16.5518 27.3017C15.7518 27.7417 14.7918 27.7417 13.9918 27.3017L7.3251 23.6617C6.47177 23.1951 5.93843 22.2884 5.93843 21.3151Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2510_31147"
          x="0.0617676"
          y="3.36841"
          width="31.8767"
          height="27.2634"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2510_31147"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2510_31147"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
});
export default StudentIcon;
