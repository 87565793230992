import {
  AgriculturalCommissionIcon,
  AirportIcon,
  AmbulanceDistrictIcon,
  AquiferRechargeDistrictIcon,
  AuditoriumDistrictIcon,
  CapitalCrimesDefenseProgramIcon,
  CemeteryDistrictIcon,
  CountyIcon,
  DrainageDistrictIcon,
  FairBoardIcon,
  FireDistrictIcon,
  FloodControlDistrictIcon,
  GrazingDistrictIcon,
  HighwayDistrictIcon,
  HospitalDistrictIcon,
  InfrastructureDistrictIcon,
  IntermodalCommerceAuthorityIcon,
  IrrigationDistrictIcon,
  JointPowersIcon,
  LeveeDistrictIcon,
  LibraryDistrictIcon,
  OtherDistrictIcon,
  PestAbatementIcon,
  PestControlDistrictIcon,
  PortDistrictIcon,
  RecreationDistrictIcon,
  SewerWaterDistrictIcon,
  TVTranslatorDistrictIcon,
  TransportationAuthorityIcon,
  UrbanRenewalIcon,
  WasteDisposalIcon,
  WaterConservationIcon,
  WaterDistrictIcon,
  WaterShedIcon,
  StudentIcon,
  CityIcon
} from "../../styles/icons";
import { ENTITY_TYPE_IDS } from "../constants/entityTypeIds";

export const EntityTypes = [
  {
    name: "Abatement District",
    type: ENTITY_TYPE_IDS.ABATEMENT_DISTRICT,
    Icon: PestAbatementIcon,
  },
  {
    name: "Agricultural Commission",
    type: ENTITY_TYPE_IDS.AGRICULTURAL_COMMISSION,
    Icon: AgriculturalCommissionIcon,
  },
  { name: "Airport", type: ENTITY_TYPE_IDS.AIRPORT, Icon: AirportIcon },
  {
    name: "Ambulance District ",
    type: ENTITY_TYPE_IDS.AMBULANCE_DISTRICT,
    Icon: AmbulanceDistrictIcon,
  },
  {
    name: "Aquifer Recharge District",
    type: ENTITY_TYPE_IDS.AQUIFER_RECHARGE_DISTRICT,
    Icon: AquiferRechargeDistrictIcon,
  },
  {
    name: "Auditorium District",
    type: ENTITY_TYPE_IDS.AUDITORIUM_DISTRICT,
    Icon: AuditoriumDistrictIcon,
  },
  {
    name: "Capital Crimes Defense Program",
    type: ENTITY_TYPE_IDS.CAPITAL_CRIMES_DEFENSE_PROGRAM,
    Icon: CapitalCrimesDefenseProgramIcon,
  },
  {
    name: "Cemetery District",
    type: ENTITY_TYPE_IDS.CEMETERY_DISTRICT,
    Icon: CemeteryDistrictIcon,
  },
  { name: "City", type: ENTITY_TYPE_IDS.CITY, Icon: CityIcon },
  { name: "County", type: ENTITY_TYPE_IDS.COUNTY, Icon: CountyIcon },
  {
    name: "Drainage District",
    type: ENTITY_TYPE_IDS.DRAINAGE_DISTRICT,
    Icon: DrainageDistrictIcon,
  },
  { name: "FairBoard", type: ENTITY_TYPE_IDS.FAIR_BOARD, Icon: FairBoardIcon },
  {
    name: "Fire District",
    type: ENTITY_TYPE_IDS.FIRE_DISTRICT,
    Icon: FireDistrictIcon,
  },
  {
    name: "Flood Control District",
    type: ENTITY_TYPE_IDS.FLOOD_CONTROL_DISTRICT,
    Icon: FloodControlDistrictIcon,
  },
  {
    name: "Grazing District",
    type: ENTITY_TYPE_IDS.GRAZING_DISTRICT,
    Icon: GrazingDistrictIcon,
  },
  {
    name: "Highway District",
    type: ENTITY_TYPE_IDS.HIGHWAY_DISTRICT,
    Icon: HighwayDistrictIcon,
  },
  {
    name: "Hospital District",
    type: ENTITY_TYPE_IDS.HOSPITAL_DISTRICT,
    Icon: HospitalDistrictIcon,
  },
  {
    name: "Infrastructure District",
    type: ENTITY_TYPE_IDS.INFRASTRUCTURE_DISTRICT,
    Icon: InfrastructureDistrictIcon,
  },
  {
    name: "Intermodal Commerce Authority",
    type: ENTITY_TYPE_IDS.INTERMODAL_COMMERCE_AUTHORITY,
    Icon: IntermodalCommerceAuthorityIcon,
  },
  {
    name: "Irrigation District",
    type: ENTITY_TYPE_IDS.IRRIGATION_DISTRICT,
    Icon: IrrigationDistrictIcon,
  },
  {
    name: "Joint Powers",
    type: ENTITY_TYPE_IDS.JOINT_POWERS,
    Icon: JointPowersIcon,
  },
  {
    name: "Junior College District",
    type: ENTITY_TYPE_IDS.JUNIOR_COLLEGE_DISTRICT,
    Icon: StudentIcon,
  },
  {
    name: "Levee District",
    type: ENTITY_TYPE_IDS.LEVEE_DISTRICT,
    Icon: LeveeDistrictIcon,
  },
  {
    name: "Library District",
    type: ENTITY_TYPE_IDS.LIBRARY_DISTRICT,
    Icon: LibraryDistrictIcon,
  },
  {
    name: "Other",
    type: ENTITY_TYPE_IDS.OTHER_DISTRICT,
    Icon: OtherDistrictIcon,
  },
  {
    name: "Pest Control District",
    type: ENTITY_TYPE_IDS.PEST_CONTROL_DISTRICT,
    Icon: PestControlDistrictIcon,
  },
  {
    name: "Port District",
    type: ENTITY_TYPE_IDS.PORT_DISTRICT,
    Icon: PortDistrictIcon,
  },
  {
    name: "Recreation District",
    type: ENTITY_TYPE_IDS.RECREATION_DISTRICT,
    Icon: RecreationDistrictIcon,
  },
  {
    name: "Sewer Water District",
    type: ENTITY_TYPE_IDS.SEWER_WATER_DISTRICT,
    Icon: SewerWaterDistrictIcon,
  },
  {
    name: "Sewer District",
    type: ENTITY_TYPE_IDS.SEWER_DISTRICT,
    Icon: SewerWaterDistrictIcon,
  },
  {
    name: "Soil Water District",
    type: ENTITY_TYPE_IDS.SOIL_WATER_DISTRICT,
    Icon: SewerWaterDistrictIcon,
  },
  {
    name: "Soil District",
    type: ENTITY_TYPE_IDS.SOIL_WATER_DISTRICT,
    Icon: SewerWaterDistrictIcon,
  },
  {
    name: "Transportation Authority",
    type: ENTITY_TYPE_IDS.TRANSPORTATION_AUTHORITY,
    Icon: TransportationAuthorityIcon,
  },
  {
    name: "TV Translator District",
    type: ENTITY_TYPE_IDS.TV_TRANSLATOR_DISTRICT,
    Icon: TVTranslatorDistrictIcon,
  },
  {
    name: "Urban Renewal",
    type: ENTITY_TYPE_IDS.URBAN_RENEWAL,
    Icon: UrbanRenewalIcon,
  },
  {
    name: "Waste Disposal",
    type: ENTITY_TYPE_IDS.WASTE_DISPOSAL,
    Icon: WasteDisposalIcon,
  },
  {
    name: "Water Conservation",
    type: ENTITY_TYPE_IDS.SOIL_WATER_DISTRICT,
    Icon: WaterConservationIcon,
  },
  { name: "Watershed", type: ENTITY_TYPE_IDS.WATERSHED, Icon: WaterShedIcon },
  {
    name: "Water District",
    type: ENTITY_TYPE_IDS.WATER_DISTRICT,
    Icon: WaterDistrictIcon,
  },
];

export const handleTypeIcon = (
  type?: number
): React.ComponentType<React.SVGProps<SVGSVGElement>> | undefined => {
  const foundEntity = EntityTypes.find((entity) => entity.type === type);
  return foundEntity?.Icon;
};
