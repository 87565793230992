import { FC } from "react";
import "./yearTagPill.scss";

type YearTagPillType = {
  selectedYear: string;
  year: string;
  setSelectedYear: React.Dispatch<React.SetStateAction<string>>;
};

const YearTagPill: FC<YearTagPillType> = ({
  selectedYear,
  year,
  setSelectedYear,
}) => {
  return (
    <p
      key={year}
      onClick={() => setSelectedYear(year)}
      className={`tag-pill${year === selectedYear ? "-filled" : ""} body-small`}
    >
      {`YEAR ${year}`}
    </p>
  );
};

export default YearTagPill;
