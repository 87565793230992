import { Link } from "react-router-dom";
import { ChevronRightCircle } from "styled-icons/boxicons-solid";
import { HOME_PAGE_CONSTANTS } from "../../types/constants";
import "./quickLinkCard.scss";

export type QuickLinkCardProps = {
  title: string;
  description: string;
  imgLink: string;
  navTag: string;
  buttonNavigatorLink: string;
  positionImageTop?: boolean;
};

const QuickLinkCard = (props: QuickLinkCardProps) => {
  const {
    title,
    description,
    buttonNavigatorLink,
    imgLink,
    navTag,
    positionImageTop,
  } = props;

  return (
    <Link
      style={{ textDecoration: "none" }}
      to={buttonNavigatorLink}
      state={{ tag: navTag }}
    >
      <div className="quick-link-card">
        <img
          className={
            positionImageTop
              ? "quick-link-image image-position-top"
              : "quick-link-image"
          }
          src={imgLink}
          alt=""
        />
        <div className="text-container">
          <span>
            <h6 className="title">{title}</h6>
            <p className="description body-medium">{description}</p>
          </span>
        </div>
        <div className="read-more-container">
          <p className="read-more-text">
            {HOME_PAGE_CONSTANTS.buttons.READ_MORE}
          </p>
          <ChevronRightCircle
            className="read-more-icon"
            size="20px"
            color="#404E65"
          />
        </div>
      </div>
    </Link>
  );
};

export default QuickLinkCard;
