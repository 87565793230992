export const API_CONSTANTS = {
  // apiBase:
  // eslint-disable-next-line
  // process.env.REACT_APP_APIBASE ?? "https://localtransparency.idaho.gov/api",
  apiBase:
    //   eslint-disable-next-line
    process.env.REACT_APP_APIBASE ??
    "https://d39ixgxz8nrle5.cloudfront.net/api",
  imageBucket:
    //eslint-disable-next-line
    process.env.REACT_APP_IMAGEBUCKET ??
    "https://sco-loctrans-images.s3-us-gov-west-1.amazonaws.com", //SCO
  // imageBucket: process.env.REACT_APP_IMAGEBUCKET ?? "https://sco-image-bucket.s3.us-west-2.amazonaws.com", //ITT Dev
};
