import { FC, memo } from "react";

const FairBoardIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1822 3.71064C12.2553 4.60597 11.9344 4.7283 10.9108 4.5773C9.53659 4.37464 9.23084 4.65397 8.93752 6.38097C8.76813 7.37797 8.52056 7.69864 7.54696 8.18164C6.22094 8.8393 6.14337 9.1403 6.86758 10.8113C7.34363 11.9093 7.34121 12.0553 6.82486 13.315C6.20215 14.834 6.39154 15.3243 7.85816 15.9926C8.51602 16.2923 8.77874 16.6873 8.9357 17.612C9.24145 19.4116 9.55144 19.677 11.0784 19.4466C12.2614 19.2683 12.5195 19.359 13.4877 20.2943C14.6604 21.427 15.0734 21.3833 16.2055 20.007C16.6703 19.442 17.0997 19.2993 18.1718 19.3526C20.2335 19.455 20.249 19.445 20.4647 17.861C20.632 16.635 20.8178 16.3183 21.5699 15.9756C23.2789 15.1966 23.4177 14.7973 22.5056 13.281C21.712 11.962 21.7047 11.9033 22.2086 10.9376C23.0034 9.4143 22.8634 8.83797 21.5405 8.18164C20.5669 7.69864 20.3193 7.37797 20.1499 6.38097C19.846 4.5913 19.5345 4.32597 18.0091 4.55597C16.8267 4.7343 16.5679 4.6433 15.6034 3.71197C15.0088 3.13764 14.464 2.66797 14.3922 2.66797C14.3204 2.66797 13.7759 3.1373 13.1822 3.71064ZM6.83971 19.1796C6.53032 19.9066 5.76217 21.6356 5.13279 23.022L3.98828 25.543L5.9567 25.2316C7.07788 25.0543 8.03876 25.0456 8.18936 25.2113C8.33481 25.3713 8.8457 26.2903 9.32448 27.2536L10.1951 29.005L11.6526 25.6623C13.0471 22.4636 13.0874 22.292 12.5847 21.681C12.1759 21.184 11.6993 21.0563 10.4347 21.1053C8.56299 21.1776 7.93028 20.6986 7.60817 18.9663L7.40211 17.8576L6.83971 19.1796ZM21.5132 19.0163C21.5132 19.3913 21.1626 20.084 20.7341 20.5553C20.0614 21.2953 19.7866 21.3826 18.7196 21.195C17.7039 21.0163 17.3612 21.105 16.7909 21.6943L16.0973 22.411L17.51 25.5396C18.2869 27.2603 18.9942 28.6673 19.0817 28.667C19.169 28.6663 19.6484 27.834 20.1466 26.8176L21.0529 24.9696L22.2677 25.17C22.9362 25.2803 23.8577 25.4493 24.3161 25.5456C24.7746 25.642 25.1494 25.636 25.1494 25.5316C25.1494 25.0836 21.9589 18.3346 21.7471 18.3346C21.6183 18.3346 21.5132 18.6413 21.5132 19.0163Z"
        fill="black"
      />
    </svg>
  );
});
export default FairBoardIcon;
