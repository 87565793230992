import { FC, memo } from "react";

const PayIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="40"
      height="36"
      viewBox="0 0 40 36"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9528 7.75736C13.203 6.63214 14.8987 6 16.6668 6C18.4349 6 20.1306 6.63214 21.3809 7.75736C22.6311 8.88258 23.3335 10.4087 23.3335 12C23.3335 13.5913 22.6311 15.1174 21.3809 16.2426C20.1306 17.3679 18.4349 18 16.6668 18C14.8987 18 13.203 17.3679 11.9528 16.2426C10.7025 15.1174 10.0002 13.5913 10.0002 12C10.0002 10.4087 10.7025 8.88258 11.9528 7.75736ZM3.3335 27C3.3335 23.685 9.30016 21 16.6668 21C17.6002 21 18.5168 21.045 19.4002 21.135C18.7007 22.5042 18.3369 23.993 18.3335 25.5C18.3359 27.0571 18.723 28.5942 19.4668 30H3.3335V27Z"
        fill="currentColor"
      />
      <path
        d="M31.6331 25.0222C29.4032 24.4978 28.6861 23.9556 28.6861 23.1111C28.6861 22.1422 29.6783 21.4667 31.3384 21.4667C33.0869 21.4667 33.7353 22.2222 33.7942 23.3333H35.9651C35.8964 21.8044 34.8649 20.4 32.8119 19.9467V18H29.8649V19.92C27.9592 20.2933 26.4268 21.4133 26.4268 23.1289C26.4268 25.1822 28.303 26.2044 31.0437 26.8C33.4995 27.3333 33.9907 28.1156 33.9907 28.9422C33.9907 29.5556 33.5093 30.5333 31.3384 30.5333C29.3148 30.5333 28.5192 29.7156 28.4111 28.6667H26.25C26.3679 30.6133 27.9789 31.7067 29.8649 32.0711V34H32.8119V32.0889C34.7274 31.76 36.25 30.7556 36.25 28.9333C36.25 26.4089 33.863 25.5467 31.6331 25.0222Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default PayIcon;
