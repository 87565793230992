import { FC, memo } from "react";

const PestControlDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="24"
        height="30"
        viewBox="0 0 24 30"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 8.33333H19.785C19.11 7.03333 18.18 5.91667 17.055 5.06667L19.5 2.35L17.385 0L14.13 3.61667C13.44 3.43333 12.735 3.33333 12 3.33333C11.265 3.33333 10.56 3.43333 9.885 3.61667L6.615 0L4.5 2.35L6.93 5.06667C5.82 5.91667 4.89 7.03333 4.215 8.33333H0V11.6667H3.135C3.06 12.2167 3 12.7667 3 13.3333V15H0V18.3333H3V20C3 20.5667 3.06 21.1167 3.135 21.6667H0V25H4.215C5.775 27.9833 8.67 30 12 30C15.33 30 18.225 27.9833 19.785 25H24V21.6667H20.865C20.94 21.1167 21 20.5667 21 20V18.3333H24V15H21V13.3333C21 12.7667 20.94 12.2167 20.865 11.6667H24V8.33333ZM15 21.6667H9V18.3333H15V21.6667ZM15 15H9V11.6667H15V15Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default PestControlDistrictIcon;
