export const NAV_TAGS = {
  STATE_BUDGET: "state-budget",
  STATE_BUDGET_TO_ACTUAL: "state-budget-to-actual",
  STATE_TRANSACTIONS: "state-transactions",
  STATE_AGENCIES: "state-agencies",
  STATE_ABOUT: "state-about",

  EMPLOYEE_PAY_RATES: "employee-pay-rates",
  EMPLOYEE_COUNT: "employee-count",
  EMPLOYEE_HISTORY: "employee-history",
  EMPLOYEE_HIGHEST_PAID: "Workforce&Salary-highest-paid-employees",
  TOP_VENDOR_TOTAL_SPENT: "top-vendor-total-spent",
  TENURE: "Tenure",

  EXPLORE_EDUCATION_DATA: "explore-education-data",
  EDUCATION_YEAR_OVER_YEAR: "education-year-over-year",
  EDUCATION_SALARY_COSTS: "education-salary-costs",

  EXPLORE_COUNTIES_DATA: "explore-counties-data",
  COUNTIES_TOP_TEN_COUNTIES_BY_EXPENDITURE:
    "county-top-ten-counties-by-expenditure",
  COUNTIES_ALL_COUNTY_FUND_BALANCES: "county-all-county-budgets",
  PROPERTY_TAX_BY_PERCENT_OF_BUDGET: "property-tax-by-percent-of-budget",

  EXPLORE_CITY_DATA: "explore-city-data",
  CITY_ALL_CITY_BUDGETS: "city-all-city-budgets",
  CITY_SALARY_COSTS: "city-salary-costs",

  EXPLORE_LOCAL_DISTRICT_DATA: "explore-local-district-data",
  LOCAL_DISTRICT_ALL_LOCAL_DISTRICT_BUDGETS:
    "local-district-all-local-district-budgets",
  Local_DISTRICT_URBAN_RENEWAL: "local-district-urban-renewal",

  WORKFORCE_SALARY: "Workforce&Salary",
  STATE: "State",
  EDUCATION: "Education",
  COUNTY: "Counties",
  CITY: "City",
  LOCAL_DISTRICT: "LocalDistrict",
  VENDOR_PAYMENTS: "VendorPayments",
  PUBLIC_RECORDS: "PublicRecordRequest",
  COMING_SOON: "COMING SOON",
};
