import {
  BasicEntity,
  County,
  EntityFilter,
  EntityFinancialFilter,
} from "../../types/interfaces/entityInterfaces";
import { ENTITY_TYPE_IDS } from "../../types/constants";
import { handleTypeIcon } from "../../types/enums/iconsEnum";
import {
  requestCountyNarratives,
  requestCountyFacts,
  requestSuggestEntity,
  requestEntityFinancials,
  requestEntityReportInfo,
  requestEntitiesByTypeId,
  requestCountyEntitiesByEntityTypeID,
  requestCountyEntityTypes,
  requestCountyHasEntityList,
} from "../../api/searchType.api";

export const getCountyById = async (countyId: string) => {
  try {
    const countyFacts = await requestCountyFacts(countyId);
    const budgetData = await requestEntityFinancials(countyId, "2023");
    const auditReports = await requestEntityReportInfo(countyId, "2022");
    const countyNarratives = await requestCountyNarratives(countyId);

    const countyData: County = {
      budget: Math.ceil(budgetData[0].ExpenseBudget),
      population: countyFacts[0].CountyCensusEstimate,
      sizeSqMiles: countyFacts[0].CountySquareMiles,
      id: Number(countyId),
      name: countyFacts[0].County.trim(),
      narratives: countyNarratives[0],
      budgetReport: budgetData[0].ReportDocs[0]?.Url
        ? budgetData[0].ReportDocs[0]?.Url
        : null,
      auditReport: auditReports ? auditReports.ReportDocs[0].Url : null,
    };

    return countyData;
  } catch (error) {
    return error;
  }
};

export const getEntitiesFilter = async (userInput: string) => {
  const data = await requestSuggestEntity(userInput);

  //eslint-disable-next-line
  const entitiesData: BasicEntity[] = data.map((entity: any) => {
    return {
      id: entity.EntityID,
      name: entity.EntityName,
      type: entity.EntityType,
    };
  });
  return entitiesData;
};

export const getEntitiesByTypeId = async (typeId: string) => {
  const data = await requestEntitiesByTypeId(typeId);

  //eslint-disable-next-line
  const entitiesData: EntityFilter[] = data.map((entity: any) => {
    return {
      id: entity.EntityID,
      name: entity.EntityName,
    };
  });
  return entitiesData;
};

export const getCountyEntitiesByEntityTypeID = async (
  entityTypeId: string | number,
  countyId?: string | number,
  fiscalYear?: string | number
) => {
  const allowNoRecords = true;
  const data = await requestCountyEntitiesByEntityTypeID(
    entityTypeId,
    fiscalYear,
    countyId,
    allowNoRecords
  );

  //eslint-disable-next-line
  const entitiesData: EntityFinancialFilter[] = data.map((entity: any) => {
    return {
      id: entity.EntityID,
      name: entity.EntityName,
      revenueActual: entity.Actual_Revenue,
      expenseActual: entity.Actual_Expenditures,
      revenueBudget: entity.Budgeted_Revenue,
      expenseBudget: entity.Budgeted_Expenditures,
      ...(entity.ReportDocs && { reportUrl: entity.ReportDocs[0]?.Url }),
      ...(entity.ReportDocs && {
        reportName: entity.ReportDocs[0]?.ReportName,
        reportDocs: entity.ReportDocs,
      }),
    };
  });
  return entitiesData;
};

export const getCountyEntityTypes = async (countyId: string) => {
  const data = await requestCountyEntityTypes(countyId);

  //eslint-disable-next-line
  const entitiesData: EntityFinancialFilter[] = data.map((entity: any) => {
    return {
      type: entity.EntityTypeID,
      name: entity.EntityType,
      Icon: handleTypeIcon(entity.EntityTypeID),
    };
  });
  return entitiesData;
};

export const getCountyEntityList = async (countyId?: string | number) => {
  const data = await requestCountyHasEntityList(countyId || "0");
  //eslint-disable-next-line
  const entitiesData: EntityFilter[] = data
    .filter((entity: any) => {
      return entity.EntityTypeID !== ENTITY_TYPE_IDS.STATE_AGENCY;
    })
    .map((entity: any) => {
      return {
        type: entity.EntityTypeID,
        name: entity.EntityType,
        Icon: handleTypeIcon(entity.EntityTypeID),
        hasThisEntity: countyId ? entity.CountyHasEntityType : "TRUE",
      };
    });
  return entitiesData;
};
