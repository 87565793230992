import { FC, memo } from "react";

const WaterShedIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8067 0L0 16.9412H5.04202V32H28.5714V16.9412H33.6134L16.8067 0ZM16.8067 24.4706C14.958 24.4706 13.4454 22.7765 13.4454 20.7059C13.4454 18.6353 16.8067 13.1765 16.8067 13.1765C16.8067 13.1765 20.1681 18.6353 20.1681 20.7059C20.1681 22.7765 18.6555 24.4706 16.8067 24.4706Z"
        fill="black"
      />
    </svg>
  );
});
export default WaterShedIcon;
