import { FC, memo } from "react";

const RecreationDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="29"
        height="32"
        viewBox="0 0 29 32"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="28.5714" height="32" fill="url(#pattern0)" />
        <defs>
          <pattern
            id="pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0_2808_81349"
              transform="translate(-0.06) scale(0.0116667 0.0104167)"
            />
          </pattern>
          <image
            id="image0_2808_81349"
            width="96"
            height="96"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAE1UlEQVR4nO2cS2hdRRiAv6hNwCQ2ElGJaCnYVk2Kz4UuXKkLKwqCiAhVUawi4kLQpbhQF4qhFatGhYIo4kqqUt9PfCAGEZtYqyISX/Rla1NpDUmOi/9GL/E85tw7888k+T/4oYvmfP/Mf8/MPWdmLhiGYRiGYRiGYRiGYRiGYRiGYRiGYRhGOqwFNgFjwKFGjAEbgaGIeanTCaxQ9HUBTwAzQFYQM8DmRm5arAEGFH0ArAJGge+AXgVfF/AexR0/P95Fpwh9SB/8Blyk4ANgPXCQ/xr7ooLzSdw7fy4eD5zTUcCrTb6/gQ0hhb3Ac+Q39vaA3rWUDztFMQ0MBszr/gLvCAHuvvOQW62osUca/ycEm0q8VTEcKKcrKf9QjAKn+ZL1AQdKZHMRaj4Yd3AXxfYA+awG9ju4dwLH+JLe5iDMgJd8CZuYdHTnxUHPufQgRa3yzgLXenYXjv+h54N2CvCnxzw6kA+Yi/dBj95/6QW+dZAfBs7x6E1lCLrX0bkN+YYUhCHgL4ckvgeO8+Tc6OArikc95XAJ8q2qyvcj0O/JWcgtDolkwCvA0R58Q7g1fn74+hq6Btjj4JtE8VWI63zwtCffZkdfczzmwduP3M1VrlngGg8+Z7qBbxwSy4AHPPg6kdcLrp3/Du0/DPUAHzn6HmrT1RKDuM0HGfAssKxNXyfyeqFsOJpGPvntdv4A8GWJpzmCTrpV3OSQ4Fy8iZ8JahB5wt2OjLuTjX8P42fMvwCYwK1NPwDHe3C2xcO4F2EXcF2cNCvpQYroOuHvB86Ikuk8OoDnqTdBvg5cHCPZHI5FnvR/wj3/GeR9UDJ0Am9RrwgZMs7eDCzXT5lzgUeAfTXynYs7IuRbSS/wPvUbkyFvU19GhqfugDkOAPfg9j4nL2aRJ+Jk6QJeoLXGpR7TBF5w8UUHxYsVCzX2Apf57CQNrsdtHSH1eBuPiyvarABeI34nthK7gVuRO3rBcwXwFfE71SUOIO/zY3wzC0oHcBXwBq0tsoeOceBuFmHH53EqcBfyeuII8Tr9D2ALcGnY5qZNPzBFnAIs6Y5v5jP0O3+GBIabaK9R5/FBBOcO/C7at0QqBfgwgvPzCM7/kUoBPkaGBE2SKEBK7ER3Djhdp1nlpHIHAHyt6JpAVrOik1IBxhVdo4quUlIqQIiNtEWMKbpKSakAPyu6flV0lZJSAXYruvYqukpJqQC7FF17FF2lpFSAw8iRUw32KXkqSakAIAfdNMiUPJWkVgCt8709Sp5KlmoBQm59qYUVIDIpFWA5egvgJyl5KkmpAKsVXUlsqoW0CrBK0XWmoquUpVoAuwNyOEvRtRLZlh6dlAqg9nMvyOnNsxV9haRSgJXIXqG6jND6SczzW/y7RckN1F9S/BTZDr8M99OMzbFFpWULhGeo13m/A6c0/f3JwC81r6G5AJQ8O3DvuCnyz5ddSL1tjtMk9EQckxOQoz+uHVd2SuXGGtfJ0J34k+Vq3DvsKYfrjdS43p0e27FgGabepFtFnUnZJmLgC+pPulW4TsrRJ2IfPyfTLn3Izw10k79beQq4HPlxEFcOITuu15Pfxgnkxz+2AZ/USXaxcyKwDrgP2IpsH2nnaOiGxjW2Nq65ruEwDMMwDMMwDMMwDMMwDMMwDMNQ4x/VJ4vFu8E9sgAAAABJRU5ErkJggg=="
          />
        </defs>
      </svg>
    );
  }
);
export default RecreationDistrictIcon;
