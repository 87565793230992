import { BasicEntity, County } from "../../types/interfaces/entityInterfaces";
import { ENTITY_TYPE_IDS, ERROR_CONSTANTS } from "../../types/constants";
import {
  requestCountyFacts,
  requestEntitiesByTypeId,
  requestFinancialsByEntityType,
} from "../../api/searchType.api";

export const getCounties = async () => {
  const data = await requestEntitiesByTypeId(String(ENTITY_TYPE_IDS.COUNTY));

  //eslint-disable-next-line
  let countiesData: BasicEntity[] = data.map((county: any) => {
    return {
      id: county.EntityID,
      name: county.EntityName,
    };
  });

  countiesData = sortFilterData(countiesData);
  return countiesData;
};

export const getCountyQuickFacts = async (fiscalYear: string) => {
  try {
    const countyQuickFactsData = await requestCountyFacts();
    const budgetData = await requestFinancialsByEntityType(
      String(ENTITY_TYPE_IDS.COUNTY),
      fiscalYear
    );
    //eslint-disable-next-line
    const countyData: County[] = countyQuickFactsData.map((county: any) => {
      return {
        budget: "",
        population: county.CountyCensusEstimate,
        sizeSqMiles: county.CountySquareMiles,
        id: county.CountyID,
        name: county.County.trim(),
        type: "County",
        about: "",
      };
    });
    countyData.forEach((county) => {
      //eslint-disable-next-line
      budgetData.forEach((budget: any) => {
        if (county.id === budget.EntityID) {
          county.budget = Math.ceil(budget.ExpenseBudget);
        }
      });
    });
    return countyData;
  } catch {
    return ERROR_CONSTANTS.SERVER_ERROR;
  }
};

const sortFilterData = (data: BasicEntity[]) => {
  data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  return data;
};
