import { FC, memo } from "react";

const CemeteryDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6656 3.51706C11.1656 3.79906 10.582 4.28273 10.3687 4.5924C10.1554 4.90206 9.41842 5.30806 8.73056 5.49473C7.98271 5.69773 7.24516 6.18273 6.89577 6.7014C6.32094 7.5544 6.32034 7.71507 6.85123 16.4514L7.39091 25.3341H14.5443H21.6977L22.2374 16.4514C22.7683 7.71507 22.7677 7.5544 22.1929 6.7014C21.8589 6.20573 21.0996 5.6914 20.4208 5.50073C19.7675 5.3174 18.9224 4.82406 18.543 4.40406C18.1636 3.9844 17.5091 3.49673 17.0882 3.32073C15.9 2.82406 12.6892 2.94006 11.6656 3.51706ZM10.9081 12.6674C10.9081 13.2971 11.1102 13.3341 14.5443 13.3341C17.9785 13.3341 18.1806 13.2971 18.1806 12.6674C18.1806 12.0377 17.9785 12.0007 14.5443 12.0007C11.1102 12.0007 10.9081 12.0377 10.9081 12.6674ZM10.9081 16.6674C10.9081 17.2971 11.1102 17.3341 14.5443 17.3341C17.9785 17.3341 18.1806 17.2971 18.1806 16.6674C18.1806 16.0377 17.9785 16.0007 14.5443 16.0007C11.1102 16.0007 10.9081 16.0377 10.9081 16.6674ZM4.84766 28.0007V29.3341H14.5443H24.241V28.0007V26.6674H14.5443H4.84766V28.0007Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default CemeteryDistrictIcon;
