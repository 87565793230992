import { FC, memo } from "react";

const ArrowRightIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Arrow Right">
        <rect y="0.904297" width="18" height="18" rx="9" fill="#404E65" />
        <path
          id="Vector"
          d="M10.125 13.973C10.0125 13.8605 9.95625 13.7262 9.95625 13.5699C9.95625 13.4137 10.0063 13.2855 10.1063 13.1855L12.8625 10.4293H3.5625C3.4 10.4293 3.26562 10.3762 3.15938 10.2699C3.05313 10.1637 3 10.0293 3 9.8668C3 9.7043 3.05313 9.56992 3.15938 9.46367C3.26562 9.35742 3.4 9.3043 3.5625 9.3043H12.8625L10.0875 6.5293C9.975 6.4168 9.92188 6.28555 9.92813 6.13555C9.93438 5.98555 9.99375 5.8543 10.1063 5.7418C10.2188 5.6418 10.3531 5.5918 10.5094 5.5918C10.6656 5.5918 10.7938 5.6418 10.8938 5.7418L14.625 9.47305C14.6875 9.53555 14.7313 9.59805 14.7563 9.66055C14.7812 9.72305 14.7938 9.7918 14.7938 9.8668C14.7938 9.9418 14.7812 10.0105 14.7563 10.073C14.7313 10.1355 14.6875 10.198 14.625 10.2605L10.9125 13.973C10.8 14.0855 10.6688 14.1418 10.5188 14.1418C10.3688 14.1418 10.2375 14.0855 10.125 13.973Z"
          fill="#F2F7FF"
        />
      </g>
    </svg>
  );
});

export default ArrowRightIcon;
