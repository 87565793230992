export const FOOTER_CONSTANTS = {
  brandonPill: {
    TRANSPARENT_IDAHO: "Transparent Idaho is brought to you by",
  },
  stateControllersOffice: {
    STATE_CONTROLLER: "STATE CONTROLLER’S OFFICE",
    ADDRESS_LINE_1: "700 W State St.",
    ADDRESS_LINE_2: "P.O. Box 83720",
    ADDRESS_LINE_3: "Boise, ID 83720-0011",
  },
  footerBottom: {
    SITE_POLICIES: "Site Policies and Privacy",
    HAVING_TROUBLE: "Having trouble finding something?",
    COPYRIGHT: "Copyright Reserved 2022",
    CONTACT_US: "CONTACT US",
    PUBLIC_RECORDS: "PUBLIC RECORDS",
    IMPORTANT_LINKS: "Important Links",
  },
};
