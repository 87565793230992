import { FC, memo } from "react";

const UrbanRenewalIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7.27273V11.6364L24 5.81818L16 0V4.36364C7.16 4.36364 0 9.57091 0 16C0 18.2836 0.92 20.4073 2.48 22.1964L5.4 20.0727C4.5 18.8655 4 17.4691 4 16C4 11.1855 9.38 7.27273 16 7.27273ZM29.52 9.80364L26.6 11.9273C27.48 13.1491 28 14.5309 28 16C28 20.8145 22.62 24.7273 16 24.7273V20.3636L8 26.1818L16 32V27.6364C24.84 27.6364 32 22.4291 32 16C32 13.7164 31.08 11.5927 29.52 9.80364V9.80364Z"
        fill="black"
      />
    </svg>
  );
});
export default UrbanRenewalIcon;
