import { FC, memo } from "react";

const AgriculturalCommissionIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.80638 5.04106C3.16758 7.43973 4.34087 10.1764 5.61265 11.5851C6.76383 12.8604 7.03685 13.0017 8.34893 13.0017C9.15072 13.0017 10.2467 12.8267 10.7843 12.6131C11.6912 12.2521 11.8176 12.2941 12.5385 13.1964C13.2054 14.0307 13.3167 14.4871 13.3248 16.4184C13.333 18.3334 13.4136 18.6691 13.8648 18.6731C14.1563 18.6754 14.7018 18.7681 15.0769 18.8784C15.7323 19.0717 15.7587 18.9964 15.7587 16.9321C15.7587 15.1274 15.8675 14.6767 16.4405 14.1094C17.575 12.9861 17.8517 12.9291 18.6813 13.6467C20.794 15.4751 23.4279 13.9014 25.4632 9.59473C26.8305 6.7014 26.8099 6.66706 23.713 6.6764C22.2549 6.68073 20.5161 6.82273 19.8495 6.9924C18.4786 7.3404 17.2738 8.6584 17.2738 9.8094C17.2738 10.3391 16.8947 10.8557 16.0614 11.4624L14.849 12.3451L13.9491 11.2567C13.4542 10.6581 13.0451 9.80339 13.0403 9.35706C13.0251 7.95406 11.8382 5.73573 10.6831 4.95006C9.76404 4.3254 9.03436 4.1834 6.11294 4.0604L2.63668 3.91406L2.80638 5.04106ZM11.5731 21.3127C11.1731 22.0361 10.9179 22.2111 10.5925 21.9847C9.71798 21.3761 7.88985 21.6274 7.1326 22.4604C6.62444 23.0194 6.18415 23.2044 5.63689 23.0894C3.65423 22.6727 2.42578 24.1014 2.42578 26.8244V28.0017H14.5466C27.9592 28.0017 27.1147 28.1581 26.2329 25.8361C25.9478 25.0857 25.5272 24.6174 25.0102 24.4744C24.5763 24.3547 24.0212 23.8294 23.7751 23.3057C23.5291 22.7827 23.0657 22.2004 22.7448 22.0117C22.0121 21.5801 20.4346 21.5681 19.721 21.9881C19.2889 22.2424 19.1092 22.1421 18.8422 21.4974C18.4298 20.5014 17.1641 20.1087 15.9678 20.6054C15.3766 20.8507 14.886 20.8681 14.526 20.6564C13.4033 19.9954 12.147 20.2747 11.5731 21.3127Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default AgriculturalCommissionIcon;
