import { FC, useEffect, useState } from "react";
import "./loadingBar.scss";

export enum LoadingState {
  Idle = "idle",
  Starting = "starting",
  InProgress = "inProgress",
  Finished = "finished",
}

type LoadingBarProps = {
  loadingState: LoadingState;
  setLoadingState: React.Dispatch<React.SetStateAction<LoadingState>>;
};

const LoadingBar: FC<LoadingBarProps> = ({ loadingState, setLoadingState }) => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (loadingState === LoadingState.Starting) {
      setIsHidden(false);
    } else if (loadingState === LoadingState.Finished) {
      timeoutId = setTimeout(() => {
        setIsHidden(true);
        setLoadingState(LoadingState.Idle);
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [loadingState]);

  const loadingClass = `loading-bar ${loadingState}`;
  return isHidden ? null : <div className={loadingClass} />;
};

export default LoadingBar;
