import { forwardRef } from "react";
import { ThreeDotCircleIcon } from "../../../styles/icons";
import {  VerticalCard } from "../../../components";
import { API_CONSTANTS, NAV_TAGS } from "../../../types/constants";
import "./stateAgencyAdditionalReports.scss";

type StateAdditionalReportsProps = {
  stateAgencyId: number;
  workForceTenureUrl: string;
  employeeCountUrl: string;
  projectedRetirementUrl: string;
  agencyAgreementUrl: string;
  transactionReportUrl: string;
  travelReportUrl: string;
  agencySpendPlanUrl: string;
};

const StateAgencyAdditionalReports = forwardRef<
  HTMLDivElement,
  StateAdditionalReportsProps
>(
  (
    {
      stateAgencyId,
      workForceTenureUrl,
      employeeCountUrl,
      projectedRetirementUrl,
      agencyAgreementUrl,
      transactionReportUrl,
      travelReportUrl,
      agencySpendPlanUrl,
    },
    ref
  ) => {
    const readMoreText = "Explore Data";
    const wolfBoardId = 1370;
    const commissionOfStateLawsId = 1369;

    const handleTravelDescription = () => {
      const defaultDescription = "The Employee Travel Report provides a comprehensive breakdown of expenses accrued during employees' travels.";
      const wolfBoardDescription = "The Wolf Depredation Control Board incurs no travel expenses, resulting in the unavailability of corresponding data for this report.";
      return stateAgencyId === wolfBoardId ? wolfBoardDescription : defaultDescription;        
    }

    const handleProjectedWorkforceDesc = () => {
      const defaultDescription = "The Projected Workforce Retirement report uses years of service and predetermined retirement eligibility data.";
      const commissionOfStateLawsDescription = "The Commission on State Laws does not have data available for this report.";
      const wolfBoardDescription = "The Wolf Depredation Control Board does not have data available for this report.";
      switch (stateAgencyId) {
        case wolfBoardId:
          return wolfBoardDescription
        case commissionOfStateLawsId:
          return commissionOfStateLawsDescription
        default:
          return defaultDescription
        }
    }

    

    return (
      <>
        <div className="additional-reports-container app-padding">
          <div className="title-row">
            <ThreeDotCircleIcon className="icon" />
            <h4>Additional Agency Reports</h4>
          </div>
          <div className="more-high-level-cards-container">
            <span>
              <div className="more-high-level-cards cards">
                <a href={transactionReportUrl || '#'} 
                  onClick={(e) => !transactionReportUrl && e.preventDefault()}  
                  target="_blank" rel="noopener noreferrer">
                  <VerticalCard
                    cardTitle={"Transaction"}
                    cardDescription="The Transaction Report provides a detailed listing of every financial transaction made by the state."
                    cardTag={NAV_TAGS.STATE}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/ui-images/sco-ui-transaction.png"
                    }
                    readMoreText={readMoreText}
                  />
                </a>
                <a href={travelReportUrl || '#'} 
                  onClick={(e) => !travelReportUrl && e.preventDefault()}  
                 target="_blank" rel="noopener noreferrer">
                  <VerticalCard
                    cardTitle={"Travel"}
                    cardDescription={handleTravelDescription()}
                    cardTag={NAV_TAGS.STATE}
                    image={
                      API_CONSTANTS.imageBucket + "/ui-images/sco-ui-travel.png"
                    }
                    noReadMoreText={wolfBoardId === stateAgencyId}
                    readMoreText={readMoreText}
                  />
                </a>
                <a
                  href={agencyAgreementUrl || '#'} 
                  onClick={(e) => !agencyAgreementUrl && e.preventDefault()}  
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VerticalCard
                    cardTitle={"Agency Agreements"}
                    cardDescription="A repository of State Agency Agreements MOUs, MOAs, and Contracts."
                    cardTag={NAV_TAGS.STATE}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/ui-images/sco-ui-state-agreements.png"
                    }
                    readMoreText={readMoreText}
                  />
                </a>
              </div>
              <div className="more-high-level-cards">
                <a href={agencySpendPlanUrl|| '#'} 
                  onClick={(e) => !agencySpendPlanUrl && e.preventDefault()}   target="_blank" rel="noopener noreferrer">
                <VerticalCard
                  cardTitle="Agency Spend Plan"
                  cardDescription={
                    "The Agency Spend Plan Report offers a month-to-month agency spending aligned with their appropriated budget."
                  }
                  cardTag={NAV_TAGS.STATE}
                  image={
                    // API_CONSTANTS.imageBucket + "/ui-images/sco-ui-payroll.png"
                    API_CONSTANTS.imageBucket + "/powerbi-images/sco-report-image-6.jpg"
                  }
                  comingSoon
                  readMoreText={readMoreText}
                />
                </a>
                <a
                  href={employeeCountUrl || '#'} 
                  onClick={(e) => !employeeCountUrl && e.preventDefault()}                  
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VerticalCard
                    cardTitle={"Employee Count"}
                    cardDescription="The Employee Count Report is a resource offering insights into the ongoing employment numbers for all state agencies."
                    cardTag={NAV_TAGS.STATE}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/ui-images/sco-ui-employee-count.png"
                    }
                    readMoreText={readMoreText}
                  />
                </a>
                <a
                  href={projectedRetirementUrl || '#'} 
                  onClick={(e) => !projectedRetirementUrl && e.preventDefault()}  
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VerticalCard
                    cardTitle="Projected Workforce Retirement"
                    cardDescription={
                      handleProjectedWorkforceDesc()
                    }
                    cardTag={NAV_TAGS.STATE}
                    image={
                      API_CONSTANTS.imageBucket +
                      "/ui-images/sco-ui-workforce-tenure.png"
                    }
                    readMoreText={readMoreText}
                    noReadMoreText={wolfBoardId === stateAgencyId || commissionOfStateLawsId === stateAgencyId}

                  />
                </a>
              </div>
            </span>
          </div>
        </div>
      </>
    );
  }
);
export default StateAgencyAdditionalReports;
