import { useNavigate } from 'react-router-dom';
import { GradientButton } from '../../components';
import Header from '../header/Header';
import './somethingWentWrongPage.scss';

const SomethingWentWrongPage = () => {
  const navigate = useNavigate();

  return (
    <div className="something-went-wrong">
      <header>
        <Header />
      </header>
      <main className="something-went-wrong-container">
        <div className="something-went-wrong-text">
          <h1>Something went wrong...</h1>
          <h6>{'We are unable to load this page right now. \nPlease give it another try later.'}</h6>
        </div>
        <div>
          <GradientButton buttonText={"Reload Page"} buttonSize={"button-text-large"} ButtonAction={() => navigate(0)} />
        </div>
      </main>
    </div>
  )
}

export default SomethingWentWrongPage;