import { FC, memo } from "react";

const PopulationIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="34"
      height="27"
      viewBox="0 0 34 27"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1622_22776)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7927 14.6074C24.7855 15.8789 26.1818 17.6016 26.1818 19.8984V24H32V19.8984C32 16.918 26.8073 15.1543 22.7927 14.6074Z"
          fill="currentColor"
        />
        <path
          d="M11.6363 12.8001C14.8496 12.8001 17.4545 10.2929 17.4545 7.2001C17.4545 4.1073 14.8496 1.6001 11.6363 1.6001C8.423 1.6001 5.81812 4.1073 5.81812 7.2001C5.81812 10.2929 8.423 12.8001 11.6363 12.8001Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3636 12.8001C23.5782 12.8001 26.1818 10.2941 26.1818 7.2001C26.1818 4.1061 23.5782 1.6001 20.3636 1.6001C19.68 1.6001 19.04 1.7401 18.4291 1.9361C19.6363 3.3781 20.3636 5.2121 20.3636 7.2001C20.3636 9.1881 19.6363 11.0221 18.4291 12.4641C19.04 12.6601 19.68 12.8001 20.3636 12.8001Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6364 14.3999C7.75273 14.3999 0 16.2376 0 19.8857V24H23.2727V19.8857C23.2727 16.2376 15.52 14.3999 11.6364 14.3999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1622_22776"
          x="-2"
          y="-1"
          width="36"
          height="29.6001"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1622_22776"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1622_22776"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
});

export default PopulationIcon;
