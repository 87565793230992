import { FC, memo } from "react";

const DrainageDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.63672 7.33464V12.0013H4.8488C5.85877 12.0013 6.06089 11.8903 6.06089 11.3346C6.06089 10.7123 6.263 10.668 9.09109 10.668H12.1213V12.668V14.668H15.1782H18.2354L18.1326 11.0573C18.0369 7.67663 17.9748 7.36697 17.1569 6.18864C15.8475 4.3023 14.8849 4.0013 10.1614 4.0013C6.263 4.0013 6.06089 3.9683 6.06089 3.33464C6.06089 2.77897 5.85877 2.66797 4.8488 2.66797H3.63672V7.33464ZM12.1213 20.512C12.1213 24.2733 12.5158 25.5353 14.1097 26.874C15.2118 27.7996 15.3948 27.8386 19.1417 27.9443C22.7398 28.046 23.0301 28.102 23.0301 28.6943C23.0301 29.219 23.2488 29.3346 24.2421 29.3346H25.4542V24.668V20.0013H24.2421C23.2322 20.0013 23.0301 20.1123 23.0301 20.668C23.0301 21.279 22.8279 21.3346 20.6059 21.3346H18.1817V19.3346V17.3346H15.1515H12.1213V20.512Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default DrainageDistrictIcon;
