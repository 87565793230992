import { useNavigate } from "react-router-dom";
import { GradientButton } from "../../components";
import Header from "../header/Header";
import "./notFoundPage.scss";

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="not-found-page">
      <header>
        <Header />
      </header>
      <main className="not-found-page-container">
        <div className="not-found-page-text">
          <h1>Well... this is unexpected</h1>
          <h6>
            {
              "We can’t find the page you are looking for. \nlet’s try starting at the Local Transparency homepage."
            }
          </h6>
        </div>
        <div>
          <GradientButton
            buttonText={"Go To Homepage"}
            buttonSize={"button-text-large"}
            ButtonAction={() => navigate("/")}
          />
        </div>
      </main>
    </div>
  );
};

export default NotFoundPage;
