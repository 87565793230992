import { Phone } from "styled-icons/boxicons-solid";
import { HOME_PAGE_CONSTANTS } from "../../types/constants";
import "./navbar.scss";

const Navbar = () => {
  (function (d) {
    const s = d.createElement("script");
    s.setAttribute("data-position", "7");
    s.setAttribute("data-account", "hXusILTmOk");
    s.setAttribute("src", "https://cdn.userway.org/widget.js");
    (d.body || d.head).appendChild(s);
  })(document);

  return (
    <div className="navbar-container">
      <p className="header-text display-none body-medium">
        {HOME_PAGE_CONSTANTS.BROUGHT_BY}
      </p>
      <div className="header-right">
        <div className="phone-container display-none-phone ">
          <Phone className="phone-icon" size="24px" />
          <p className="header-phone body-medium">
            {HOME_PAGE_CONSTANTS.PHONE}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
