import { forwardRef, useState } from "react";
import { BUDGET_SECTION_CONSTANTS } from "../../../types/constants/budgetSectionConstants";
import "./stateBudgetProcess.scss";

type BudgetInfo = {
  imgUrl: string;
  months: string;
  imgAlt: string;
  title: string;
  description: string;
};

const StateBudgetProcess = forwardRef<HTMLDivElement>((_, ref) => {
  const [selectedBudget, setSelectedBudget] = useState<BudgetInfo>({
    months: BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.MONTHS,
    imgUrl: BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.IMG_URL,
    imgAlt: BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.IMG_ALT,
    title: BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.TITLE,
    description: BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.DESCRIPTION,
  });

  const BudgetContentSection = () => {
    return (
      <div className="budget-content-container">
        <img src={selectedBudget.imgUrl} alt={selectedBudget.imgAlt} />
        <div className="title-container">
          <div className="tag-container">
            <h6 className={`tag-name body-small`}>{selectedBudget.months}</h6>
          </div>
          <h3>{selectedBudget.title}</h3>
          <p className="body-large">{selectedBudget.description}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="state-budget-container" ref={ref}>
      <div className="title-container">
        <h2 className="title">STATE BUDGET PROCESS</h2>

        <p className="body-large">
          While the governmental budgeting process may seem complex, this
          concise overview empowers citizens to quickly become experts in
          Idaho's budget development. It emphasizes transparency and citizen
          engagement throughout the fiscal year, which runs from July 1 to June
          30.
        </p>
      </div>
      <div className="budget-options-container">
        <button
          aria-label="Select Budget Preparation"
          aria-selected={
            selectedBudget.title ===
            BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.TITLE
          }
          onClick={() =>
            setSelectedBudget({
              months: BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.MONTHS,
              imgUrl: BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.IMG_URL,
              imgAlt: BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.IMG_ALT,
              title: BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.TITLE,
              description:
                BUDGET_SECTION_CONSTANTS.BUDGET_PREPARATION.DESCRIPTION,
            })
          }
        >
          Budget Preparation
        </button>
        <button
          aria-label="Select Analysis Phase"
          aria-selected={
            selectedBudget.title ===
            BUDGET_SECTION_CONSTANTS.ANALYSIS_PHASE.TITLE
          }
          onClick={() =>
            setSelectedBudget({
              months: BUDGET_SECTION_CONSTANTS.ANALYSIS_PHASE.MONTHS,
              imgUrl: BUDGET_SECTION_CONSTANTS.ANALYSIS_PHASE.IMG_URL,
              imgAlt: BUDGET_SECTION_CONSTANTS.ANALYSIS_PHASE.IMG_ALT,
              title: BUDGET_SECTION_CONSTANTS.ANALYSIS_PHASE.TITLE,
              description: BUDGET_SECTION_CONSTANTS.ANALYSIS_PHASE.DESCRIPTION,
            })
          }
        >
          Analysis Phase
        </button>
        <button
          aria-label="Select Governor's Recommendation"
          aria-selected={
            selectedBudget.title ===
            BUDGET_SECTION_CONSTANTS.GOVERNORS_RECOMMENDATION.TITLE
          }
          onClick={() =>
            setSelectedBudget({
              months: BUDGET_SECTION_CONSTANTS.GOVERNORS_RECOMMENDATION.MONTHS,
              imgUrl: BUDGET_SECTION_CONSTANTS.GOVERNORS_RECOMMENDATION.IMG_URL,
              imgAlt: BUDGET_SECTION_CONSTANTS.GOVERNORS_RECOMMENDATION.IMG_ALT,
              title: BUDGET_SECTION_CONSTANTS.GOVERNORS_RECOMMENDATION.TITLE,
              description:
                BUDGET_SECTION_CONSTANTS.GOVERNORS_RECOMMENDATION.DESCRIPTION,
            })
          }
        >
          Governor's Recommendation
        </button>
        <button
          aria-label="Select Appropriations Process"
          aria-selected={
            selectedBudget.title ===
            BUDGET_SECTION_CONSTANTS.APPROPRIATIONS_PROCESS.TITLE
          }
          onClick={() =>
            setSelectedBudget({
              months: BUDGET_SECTION_CONSTANTS.APPROPRIATIONS_PROCESS.MONTHS,
              imgUrl: BUDGET_SECTION_CONSTANTS.APPROPRIATIONS_PROCESS.IMG_URL,
              imgAlt: BUDGET_SECTION_CONSTANTS.APPROPRIATIONS_PROCESS.IMG_ALT,
              title: BUDGET_SECTION_CONSTANTS.APPROPRIATIONS_PROCESS.TITLE,
              description:
                BUDGET_SECTION_CONSTANTS.APPROPRIATIONS_PROCESS.DESCRIPTION,
            })
          }
        >
          Appropriations Process
        </button>
        <button
          aria-label="Select Budget Execution"
          aria-selected={
            selectedBudget.title ===
            BUDGET_SECTION_CONSTANTS.BUDGET_EXECUTION.TITLE
          }
          onClick={() =>
            setSelectedBudget({
              months: BUDGET_SECTION_CONSTANTS.BUDGET_EXECUTION.MONTHS,
              imgUrl: BUDGET_SECTION_CONSTANTS.BUDGET_EXECUTION.IMG_URL,
              imgAlt: BUDGET_SECTION_CONSTANTS.BUDGET_EXECUTION.IMG_ALT,
              title: BUDGET_SECTION_CONSTANTS.BUDGET_EXECUTION.TITLE,
              description:
                BUDGET_SECTION_CONSTANTS.BUDGET_EXECUTION.DESCRIPTION,
            })
          }
        >
          Budget Execution
        </button>
      </div>
      <BudgetContentSection />
    </div>
  );
});
export default StateBudgetProcess;
