import { FC } from "react";

type DownloadDataIconProps = {
    iconSize: number;
    color?: string;
}

const DownloadDataIcon: FC<DownloadDataIconProps> = ({ iconSize, color }: DownloadDataIconProps) => {
    return (
        <svg width={iconSize} height={iconSize} viewBox="0 0 38 38" fill={color ? color : "currentColor"} xmlns="http://www.w3.org/2000/svg">
            <path d="M9.50004 31.6668C8.62921 31.6668 7.88398 31.357 7.26437 30.7374C6.64371 30.1167 6.33337 29.371 6.33337 28.5002V23.7502H9.50004V28.5002H28.5V23.7502H31.6667V28.5002C31.6667 29.371 31.3569 30.1167 30.7373 30.7374C30.1166 31.357 29.3709 31.6668 28.5 31.6668H9.50004ZM19 25.3335L11.0834 17.4168L13.3 15.121L17.4167 19.2377V6.3335H20.5834V19.2377L24.7 15.121L26.9167 17.4168L19 25.3335Z" />
        </svg>
    )
}

export default DownloadDataIcon;