import { FC, memo } from "react";

const PortDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3345 4.0013V5.33464H11.5164H9.69828V7.94297C9.69828 9.84964 9.49435 11.2103 8.94073 13.0013C8.52408 14.349 8.18318 15.575 8.18318 15.7263C8.18318 15.8776 11.0467 16.0013 14.5466 16.0013C18.0465 16.0013 20.9101 15.8776 20.9101 15.7263C20.9101 15.575 20.5692 14.349 20.1525 13.0013C19.5989 11.2103 19.3949 9.84964 19.3949 7.94297V5.33464H17.5768H15.7587V4.0013V2.66797H14.5466H13.3345V4.0013ZM4.95691 20.451C5.06176 21.4313 5.34721 22.8563 5.59144 23.6176C6.04052 25.018 5.84325 25.416 5.15297 24.5013C4.64541 23.8283 2.42578 23.819 2.42578 24.4896C2.42578 25.364 4.18573 27.21 5.40084 27.6103C7.05836 28.1566 8.12985 28.089 9.62253 27.3436C10.8946 26.7083 10.9261 26.7083 12.1982 27.3436C12.9064 27.6973 13.9633 27.9866 14.5466 27.9866C15.1299 27.9866 16.1869 27.6973 16.895 27.3436C18.1671 26.7083 18.1986 26.7083 19.4707 27.3436C20.9634 28.089 22.0349 28.1566 23.6924 27.6103C24.9075 27.21 26.6674 25.364 26.6674 24.4896C26.6674 23.782 24.5963 23.8516 23.843 24.5846C23.2579 25.154 23.2491 25.1443 23.4803 24.168C23.9236 22.2946 24.2169 20.503 24.2299 19.5846L24.2433 18.668H14.5048H4.76662L4.95691 20.451Z"
        fill="black"
      />
    </svg>
  );
});
export default PortDistrictIcon;
