export const WORKFORCE_SALARY_URLS = {
  EMPLOYEE_PAY_RATES: "https://stories.opengov.com/idaho/published/9XkbwanAy",
  EMPLOYEE_COUNT: "https://stories.opengov.com/idaho/published/dEiU_grgf",
  HIGHEST_PAID_STATE_EMPLOYEE:
    "https://stories.opengov.com/idaho/published/xPRWPy0sZ",
  EMPLOYEE_PAY_RANGE: "https://stories.opengov.com/idaho/published/_SK-fn9Ar",
  WORKFORCE_TENURE: "https://stories.opengov.com/idaho/published/ekYZeGtQy",
  EMPLOYEE_HISTORY: "https://stories.opengov.com/idaho/published/ufuiLOjmP",
  PROJECTED_WORKFORCE_RETIREMENT:
    "https://stories.opengov.com/idaho/published/XC-_5BLZi",
  AGENCY_HEAD: "https://stories.opengov.com/idaho/published/DW7yEqeah",
};

export const WORKFORCE_CONTROLPANEL_URLS = {
  PAY_RATES: 'https://controlpanel.opengov.com/transparency-reporting/idaho/802fa944-b831-4e4d-a8ed-440b44f54bc9/46f11b46-7029-41f1-ac2e-a8e9fa06e828?savedViewId=dac4b0a9-d9d8-4a06-978e-a573239c5a16',
  EMPLOYEE_COUNT: 'https://controlpanel.opengov.com/transparency-reporting/idaho/96f80223-1a8d-4105-aa51-b57f19fe86aa/1b937b73-93a6-43f5-82b2-96cb88051ec0?savedViewId=7cb3d703-3f96-49c9-b91c-04e270260a7b',
  HIGHEST_PAID: 'https://controlpanel.opengov.com/transparency-reporting/idaho/1004d58f-3e0f-420e-a2f1-e8893285df9f/b626c0e6-f5e2-4ea6-8fb8-c6497f8fda84?savedViewId=a1730ca5-f0fb-471c-a5bb-867c3eeb813a',
  PAY_RANGE: 'https://controlpanel.opengov.com/transparency-reporting/idaho/82c385d3-a99e-48ce-9c81-05e51666e788/32ae06a1-9590-4e66-b5fe-6470615b3540?savedViewId=27c7716d-ee37-41c0-9355-f125a08dee55',
  TENURE: 'https://controlpanel.opengov.com/transparency-reporting/idaho/6edcb574-8d1c-45a6-8289-ee8279a24abf/922c5bd6-f618-4650-be0a-175d97e138bf?savedViewId=197c8282-a28a-4d2a-97ba-f99109011345',  
  HISTORY: 'https://controlpanel.opengov.com/transparency-reporting/idaho/0b470160-e568-4c8d-8769-d3f32fc88b10/35de0f96-3f01-4f0c-8961-f624755d77ca?savedViewId=bd748b2e-e47b-4f9e-8121-7270034d8435',
  RETIREMENT: 'https://controlpanel.opengov.com/transparency-reporting/idaho/140c4e99-3cff-4469-9c02-8166d062fd0f/7e8c23d9-c501-4108-8473-5a4e52ba7903?savedViewId=6d4f0cd2-e633-4ac8-a4be-85e7d38d9eba',
  AGENCY_HEAD: 'https://controlpanel.opengov.com/transparency-reporting/idaho/406f1188-ec0e-4c28-8819-4a7ca3d59069/1c14d031-33ce-4ebd-a667-152191a669ed?savedViewId=6ec80c12-09de-4ef6-8e3b-8257861a42d2',
}

export const VENDORS_URLS = {
  TOP_VENDOR_TOTAL_SPENT: "",
  TOP_VENDOR_MOST_USED: "",
  VENDOR_TRANSACTION: "",
};

export const STATE_URLS = {
  BUDGET: "",
  BUDGET_TO_ACTUAL_EXPENDITURE: "",
  BUDGET_TO_ACTUAL_REVENUE: "",
  EMPLOYEE_PAY_RATES: "https://stories.opengov.com/idaho/published/9XkbwanAy",
  TOP_VENDOR_TOTAL_SPENT: "",
  TOP_VENDOR_MOST_USED: "",
  COVID_REPORT:
    "https://stories.opengov.com/idahorebounds/published/0_noknT6w",
  TRANSACTION: "https://controlpanel.opengov.com/transparency-reporting/idaho/2b17eed6-3282-4416-ab38-656795512745/da153265-42ce-4f81-ac3e-31da6f90766a?savedViewId=87e710d0-1c55-4548-8341-396868936d02",
  TRAVEL: "",
  EMPLOYER_PAYROLL_COST:
    "https://controlpanel.opengov.com/transparency-reporting/idaho/2b20b136-297e-48fd-b620-63a6ffa100b3/969fe63b-3f27-48dd-b447-f028e4f5e91b?savedViewId=d06d76d3-c926-4465-b814-bc3598d98f66",
  GEN_FUND_REV_AND_EXP: "",
  EMPLOYEE_COUNT: "https://controlpanel.opengov.com/transparency-reporting/idaho/96f80223-1a8d-4105-aa51-b57f19fe86aa/1b937b73-93a6-43f5-82b2-96cb88051ec0?savedViewId=7cb3d703-3f96-49c9-b91c-04e270260a7b",
  WORKFORCE_TENURE: "https://controlpanel.opengov.com/transparency-reporting/idaho/6edcb574-8d1c-45a6-8289-ee8279a24abf/922c5bd6-f618-4650-be0a-175d97e138bf?savedViewId=197c8282-a28a-4d2a-97ba-f99109011345",
};

export const EXTERNAL_URLS = {
  PAY_RATES: 'https://controlpanel.opengov.com/transparency-reporting/idaho/802fa944-b831-4e4d-a8ed-440b44f54bc9/27b100e3-97d9-483d-b6be-401af1edd93e?savedViewId=22c71bd8-812a-4c4b-bcd0-66140da71a40',
  EMPLOYER_PAYROLL_COST: 'https://controlpanel.opengov.com/transparency-reporting/idaho/802fa944-b831-4e4d-a8ed-440b44f54bc9/46f11b46-7029-41f1-ac2e-a8e9fa06e828?savedViewId=dac4b0a9-d9d8-4a06-978e-a573239c5a16',
}
