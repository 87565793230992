import { forwardRef, useEffect, useRef, useState } from "react";
import CountiesEntityFinancialTable from "./CountiesFinancialTable";
import CityFinancialTable from "../../cityLandingPage/cityComponents/CityFinancialTable";
import LocalDistrictEntityFinancialTable from "../../localDistrictsLandingPage/localDistrictComponents/LocalDistrictEntityFinancialTable";
import { VerticalCard } from "../../../components";
import { API_CONSTANTS, EXTERNAL_SITE_LINKS } from "../../../types/constants";
import { requestFiscalYearList } from "../../../api/searchType.api";
import "./commonlyRequested.scss";

const CommonlyRequested = forwardRef<HTMLDivElement>((_, ref) => {
  enum SelectedCard {
    None = 0,
    County = 1,
    City = 2,
    LocalDistricts = 3,
  }

  const [selectedCard, setSelectedCard] = useState<SelectedCard>(
    SelectedCard.None
  );
  const [fiscalYears, setFiscalYears] = useState<string[]>();

  const additionalReportsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleLoad = async () => {
      const fiscalYears = await requestFiscalYearList();
      setFiscalYears(fiscalYears);
    };

    handleLoad();
  }, []);

  const handleSelectedCard = (cardNumber: number) => {
    if (selectedCard === cardNumber) {
      setSelectedCard(SelectedCard.None);
    } else {
      setSelectedCard(cardNumber);
      additionalReportsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <>
      <div className="commonly-requested-container" ref={ref}>
        <h4>Commonly Requested</h4>
        <p className="body-large">
        Below you will find access to various commonly requested public records. See report descriptions for helpful insights into what data will be provided.
        </p>
        <div className="more-high-level-cards-container">
          <span>
            <div className="more-high-level-cards cards">
              <VerticalCard
                cardTitle="Uncashed Warrants"
                cardDescription="Click here to request access to a current list of uncashed warrants. Warrants are valid for one year, after which they are sent to 'Unclaimed Property' at the State Treasurer's Office."
                image={
                  API_CONSTANTS.imageBucket +
                  "/ui-images/sco-ui-uncashed-warrants.png"
                }
                readMoreText="See More"
                readMoreAction={() =>
                  window.open(EXTERNAL_SITE_LINKS.UNCASHED_WARRANTS, "_blank")
                }
              />
              <VerticalCard
                cardTitle="State Personnel"
                cardDescription="Click here to downloads an XLS file of current state personnel."
                image={API_CONSTANTS.imageBucket + "/ui-images/sco-ui-flag.png"}
                readMoreText="Download"
                readMoreAction={() => {
                  window.open(
                    "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/pdfs/State+Personnel+By+Year.xlsx"
                  );
                }}
              />
              <VerticalCard
                cardTitle="Employment Verification"
                cardDescription={
                  "Click here to complete a State of Idaho employment verification form."
                }
                image={
                  API_CONSTANTS.imageBucket + "/ui-images/sco-ui-computer.png"
                }
                readMoreText="See More"
                readMoreAction={() =>
                  window.open(
                    EXTERNAL_SITE_LINKS.EMPLOYMENT_VERIFICATION,
                    "_blank"
                  )
                }
              />
            </div>
            <div className="more-high-level-cards">
              <VerticalCard
                cardTitle="County"
                cardDescription="Use the search feature to search by county. You can view each county’s approved budget totals and the previous year’s actual totals."
                image={
                  API_CONSTANTS.imageBucket + "/ui-images/sco-ui-forest.png"
                }
                readMoreText={
                  selectedCard === SelectedCard.County ? "See Less" : "See More"
                }
                readMoreAction={() => handleSelectedCard(SelectedCard.County)}
              />
              <VerticalCard
                cardTitle="City"
                cardDescription="Use the search functionality to search by city. You can view each city’s approved budget totals and the previous year’s actual totals."
                image={
                  API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-6.png"
                }
                readMoreText={
                  selectedCard === SelectedCard.City ? "See Less" : "See More"
                }
                readMoreAction={() => handleSelectedCard(SelectedCard.City)}
              />
              <VerticalCard
                cardTitle="Local Districts"
                cardDescription={
                  "Use the search feature to find local districts that service the county. You can view each district’s approved budget totals and the previous year’s actual totals."
                }
                image={
                  API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-2.png"
                }
                readMoreText={
                  selectedCard === SelectedCard.LocalDistricts
                    ? "See Less"
                    : "See More"
                }
                readMoreAction={() =>
                  handleSelectedCard(SelectedCard.LocalDistricts)
                }
              />
            </div>
          </span>
        </div>
      </div>          
        <div ref={additionalReportsRef}>
          {selectedCard !== SelectedCard.None && (
            <div
            className="app-padding commonly-request-tables"
            >
              {selectedCard === SelectedCard.County && (
                <CountiesEntityFinancialTable
                fiscalYears={fiscalYears ?? []}
                customTableClass="auto-height-table"
                />
                )}

              {selectedCard === SelectedCard.City && (
                <CityFinancialTable fiscalYears={fiscalYears ?? []} />
                )}
              {selectedCard === SelectedCard.LocalDistricts && (
                <LocalDistrictEntityFinancialTable
                fiscalYears={fiscalYears ?? []}
                />
                )}
            </div>
          )}
        </div>
    </>
  );
});
export default CommonlyRequested;
