import { FC, memo } from "react";

const LibraryDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.42578 15.1585V25.0315L3.1697 25.8495C3.88877 26.6408 4.05452 26.6678 8.16954 26.6678C12.0215 26.6678 12.483 26.7312 13.0315 27.3345C13.8106 28.1915 15.2827 28.1915 16.0617 27.3345C16.6102 26.7312 17.0717 26.6678 20.9237 26.6678C25.0387 26.6678 25.2045 26.6408 25.9235 25.8495L26.6674 25.0315V15.1585V5.28516L22.2785 5.39316L17.8898 5.50116L16.8999 6.59016L15.9102 7.67949L15.8193 15.8402L15.7287 24.0012H14.5466H13.3645L13.2739 15.8402L13.183 7.67949L12.1934 6.59016L11.2034 5.50116L6.81473 5.39316L2.42578 5.28516V15.1585Z"
        fill="black"
      />
    </svg>
  );
});
export default LibraryDistrictIcon;
