import { FC, memo } from "react";

const CountyIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8734 4.30649L19.54 6.63983C19.8467 6.7465 20.1667 6.73316 20.46 6.62649L24.3667 5.10649C26.1134 4.42649 27.9934 5.7065 27.9934 7.5865V23.3865C27.9934 24.5332 27.26 25.5598 26.18 25.9198L20.86 27.7065C20.3 27.8932 19.6867 27.8932 19.1267 27.6932L12.46 25.3598C12.1667 25.2532 11.8334 25.2532 11.54 25.3732L7.63338 26.8932C5.88671 27.5732 4.00671 26.2932 4.00671 24.4132V8.61316C4.00671 7.4665 4.74005 6.45316 5.82005 6.07983L11.14 4.29316C11.7 4.10649 12.3134 4.10649 12.8734 4.30649ZM12.0067 22.3732L20.0067 25.1865V9.62649L12.0067 6.81316V22.3732Z"
        fill="black"
      />
    </svg>
  );
});
export default CountyIcon;
