import { FC, memo } from "react";

const CountyIconBubbles: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1111 9.6C23.1111 4.304 18.3289 0 12.4444 0C6.56 0 1.77778 4.304 1.77778 9.6C1.77778 16.8 12.4444 27.2 12.4444 27.2C12.4444 27.2 23.1111 16.8 23.1111 9.6ZM8.88889 9.6C8.88889 7.84 10.4889 6.4 12.4444 6.4C14.4 6.4 16 7.84 16 9.6C16 11.36 14.4178 12.8 12.4444 12.8C10.4889 12.8 8.88889 11.36 8.88889 9.6ZM0 28.8V32H24.8889V28.8H0Z"
        fill="white"
      />
    </svg>
  );
});
export default CountyIconBubbles;
