import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Agency,
  EntityFilter,
} from "../../../types/interfaces/entityInterfaces";
import { ArrowRightIcon, IdahoIcon } from "../../../styles/icons";
import { SearchComponent, SearchDropDownComponent } from "../../../components";
import IdahoStateSealImage from "../../../styles/images/idahoStateSeal.png";
import { API_CONSTANTS, URL_CONSTANTS } from "../../../types/constants";
import { getAgenciesByClassification } from "../stateAgencyPage.service";
import "./stateAgenciesSearch.scss";

const StateAgenciesSearch: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<boolean>(true);
  const [agencies, setAgencies] = useState<Agency[]>([]);
  const [agenciesForSearch, setAgenciesForSearch] = useState<EntityFilter[]>(
    []
  );
  const [agencyNameFilter, setAgencyNameFilter] = useState<string>("");
  const [agencyTypes, setAgencyTypes] = useState<EntityFilter[]>();
  const [selectedAgencyType, setSelectedAgencyType] = useState<EntityFilter>();
  const ALL_CLASSIFICATION = "All";

  useEffect(() => {
    const handleLoad = () => {
      handleGetAgencyList();
    };
    handleLoad();
  }, []);

  useEffect(() => {
    const clearAgencyNameSearch = () => {
      setAgencyNameFilter("");
    };
    clearAgencyNameSearch();
  }, [selectedAgencyType]);

  const handleGetAgencyList = async () => {
    try {
      const agencyData = await getAgenciesByClassification();
      setIsLoading(false);
      if (agencyData) {
        setAgencies(agencyData);
        setAgenciesForSearch(handleAgencies(agencyData));
        const handledAgencyData = handleTypes(agencyData);
        setAgencyTypes(handledAgencyData);
        setSelectedAgencyType(handledAgencyData[0]);
      }
    } catch {
      setErrorState(true);
    }
  };

  const handleAgencies = (agencyData: Agency[]) => {
    return agencyData.map((agency) => {
      return {
        id: Number(agency.EntityID),
        name: agency.EntityName,
      };
    });
  };

  const handleTypes = (agencyData: Agency[]) => {
    const uniqueClassifications = new Set(
      agencyData.map((agency) => agency.Classification.trim())
    );

    const uniqueClassificationsArray = Array.from(uniqueClassifications).sort();
    uniqueClassificationsArray.unshift(ALL_CLASSIFICATION);
    return uniqueClassificationsArray.map((type) => {
      return {
        name: type,
      };
    });
  };

  const AgencyCard: FC<{
    id: string;
    name: string;
    agencyNumber: string;
    classification: string;
  }> = ({ name, id, agencyNumber, classification }) => {
    return (
      <div className="agency-card">
        <div className="title-container">
          <img
            src={
              API_CONSTANTS.imageBucket +
              `/TransparentIdahoLogos/Logos/StateAgencylogo${agencyNumber}.png`
            }
            alt="Agency logo"
            onError={(e) => (e.currentTarget.src = IdahoStateSealImage)}
          />
          <span>
            <h6 className="name">{name}</h6>
            <p className="type">{classification}</p>
          </span>
        </div>
        <Link to={URL_CONSTANTS.STATE_AGENCIES + "/" + id} className="link">
          <p>Explore More</p>
          <ArrowRightIcon />
        </Link>
      </div>
    );
  };

  return (
    <div className="app-padding state-agency-search-container">
      <div className="title">
        <IdahoIcon />
        <h4>Individual State Agency Search</h4>
      </div>
      <p className="body-large sub-title">
        Interested in learning more about a specific state agency? Dive into the
        table below to filter on specific areas of state government that state
        agencies are classified under.
      </p>
      <div className="dual-search-container">
        <div>
          <h6 className="ld-entity-search-subheader">Filter Agency Type</h6>
          <div className="search-component-container">
            <SearchDropDownComponent
              allIcon
              isEntitiesFiltered
              entitiesData={agencyTypes}
              selectedItem={selectedAgencyType}
              setSelectedItem={setSelectedAgencyType}
            />
          </div>
        </div>
        <div className="or-text">
          <h6>Or</h6>
        </div>
        <div>
          <h6 className="ld-entity-search-subheader">Search Agency Name</h6>
          <div className="search-component-container">
            <SearchComponent
              entitiesData={agenciesForSearch}
              filterText={agencyNameFilter}
              setFilterText={setAgencyNameFilter}
              isEntitiesFiltered
            />
          </div>
        </div>
      </div>
      <div className="filter-table">
        {agencies?.length ? (
          agencies
            .filter((agency) => {
              if (agencyNameFilter) {
                return agency.EntityName.toLowerCase().includes(agencyNameFilter.toLowerCase());
              } else if (selectedAgencyType?.name !== ALL_CLASSIFICATION) {
                return agency.Classification.trim().includes(
                  selectedAgencyType?.name.trim() ?? ""
                );
              } else {
                return agency;
              }
            })
            .map((data: Agency) => (
              <AgencyCard
                id={data.EntityID}
                name={data.EntityName}
                agencyNumber={data.AgencyNumber}
                classification={data.Classification}
              />
            ))
        ) : (
          <AgencyCard name={""} id={""} agencyNumber="" classification="" />
        )}
      </div>
    </div>
  );
};

export default StateAgenciesSearch;
