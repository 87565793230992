import { FC, memo } from "react";

const CompareIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_884_35315)">
        <path d="M18.02 28H4V32H18.02V38L26 30L18.02 22V28ZM29.98 26V20H44V16H29.98V10L22 18L29.98 26Z" />
      </g>
      <defs>
        <clipPath id="clip0_884_35315">
          <rect width="48" height="48" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default CompareIcon;
