import {
  requestAgenciesByClassification,
  requestAgencyById,
  requestAgencyNarratives,
} from "../../api/searchType.api";
import { Agency } from "../../types/interfaces/entityInterfaces";

export const getStateAgencyById = async (agencyId: string) => {
  try {
    const data = await requestAgencyById(agencyId);
    const narrativeData = await requestAgencyNarratives(agencyId);
    return { ...data[0], Narratives: narrativeData[0].Narratives, HeroBlurb: narrativeData[0].HeroBlurb };
  } catch (error) {
    return error;
  }
};

export const getAgenciesByClassification = async (classification?: string) => {
  const data = await requestAgenciesByClassification(classification ?? "");
  return alphabetizeAgencies(data);
};

const alphabetizeAgencies = (agencies: Agency[]) => {
  const orderedAgencies = agencies.slice().sort((a, b) => {
    const nameA = a.EntityName.toLowerCase();
    const nameB = b.EntityName.toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return orderedAgencies;
};
