import DownloadDataIcon from "../../styles/icons/DownloadDataIcon"


const DownloadDataInformational = () => {
    return (
        <div className="download-data-container">
            <DownloadDataIcon iconSize={24} color={"#3E5E94"} />
            <span className="download-report-data">
                <span className="bold-text">To download data:</span> hover
                on the visualization then click the three dots that appear
                in the upper right of the report.
            </span>
        </div>
    )
}

export default DownloadDataInformational;