import { FileArrowDown } from "styled-icons/fa-solid";
import "./downloadFileComponent.scss";

type Props = {
  fileName: string;
  url: string;
}

const DownloadFileComponent = ({ fileName, url }: Props) => {
  return (
    <a href={url} target="_blank" className="download-file-container">
      <FileArrowDown className="download-icon" />
      <div className="download-text-container">
        <p className="download-text">{fileName}</p>
      </div>
    </a>
  );
};

export default DownloadFileComponent;
