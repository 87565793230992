import { FC, memo } from "react";

const IdahoIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.32147 7.7513C7.41177 12.202 7.49995 12.9473 8.03054 13.741C8.36387 14.2393 8.87537 14.6516 9.16687 14.6576C9.87776 14.6716 9.83867 15.2316 9.09112 15.7453C8.64507 16.052 8.48507 16.5226 8.48507 17.53C8.48507 18.4746 8.2975 19.0846 7.87903 19.5013C7.54571 19.833 7.27299 20.3813 7.27299 20.7196C7.27299 21.1123 7.49208 21.3346 7.87903 21.3346C8.31266 21.3346 8.48507 21.557 8.48507 22.1163C8.48507 22.5463 8.21236 23.1696 7.87903 23.5013C7.37117 24.007 7.27299 24.528 7.27299 26.7196V29.3346L10.379 29.335C12.0874 29.3353 15.5782 29.0083 18.1366 28.6086L22.788 27.882L22.5737 25.525C22.4559 24.2286 22.2034 21.903 22.0128 20.3566C21.6886 17.7233 21.6344 17.5806 21.1577 18.1066C20.878 18.4153 20.3035 18.668 19.8808 18.668C19.4223 18.668 18.9811 18.937 18.7878 19.3346C18.6093 19.7013 18.3572 20.0013 18.2272 20.0013C17.893 20.0013 16.9697 17.7783 16.9697 16.9733C16.9697 16.3973 16.2533 14.4953 15.8539 14.011C15.7827 13.9246 15.5958 14.0746 15.4382 14.3443C14.9815 15.1253 14.5555 14.1213 14.55 12.2513C14.5458 10.814 14.48 10.668 13.8349 10.668C13.1267 10.668 12.1213 9.75397 12.1213 9.10997C12.1213 8.92364 11.8486 8.49964 11.5153 8.16797C11.182 7.8363 10.9092 7.3863 10.9092 7.16797C10.9092 6.94964 10.6365 6.49964 10.3032 6.16797C9.87442 5.74097 9.69716 5.1413 9.69716 4.1163V2.66797H8.4578H7.21875L7.32147 7.7513Z"
        fill="currentColor"
      />
    </svg>
  );
});

export default IdahoIcon;
