import { FC, memo } from "react";

const QuickLinkIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="16"
      viewBox="0 0 30 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 15.5C5.375 15.5 3.59375 14.7813 2.15625 13.3438C0.71875 11.9063 0 10.125 0 8C0 5.875 0.71875 4.09375 2.15625 2.65625C3.59375 1.21875 5.375 0.5 7.5 0.5H12.75C13.0688 0.5 13.3359 0.608437 13.5516 0.825312C13.7672 1.04221 13.875 1.31096 13.875 1.63156C13.875 1.95219 13.7672 2.21875 13.5516 2.43125C13.3359 2.64375 13.0688 2.75 12.75 2.75H7.5C6 2.75 4.75 3.25 3.75 4.25C2.75 5.25 2.25 6.5 2.25 8C2.25 9.5 2.75 10.75 3.75 11.75C4.75 12.75 6 13.25 7.5 13.25H12.75C13.0688 13.25 13.3359 13.3584 13.5516 13.5753C13.7672 13.7922 13.875 14.061 13.875 14.3816C13.875 14.7022 13.7672 14.9688 13.5516 15.1813C13.3359 15.3938 13.0688 15.5 12.75 15.5H7.5ZM10.3125 9.125C9.99375 9.125 9.72656 9.01656 9.51094 8.79969C9.29531 8.58279 9.1875 8.31404 9.1875 7.99344C9.1875 7.67281 9.29531 7.40625 9.51094 7.19375C9.72656 6.98125 9.99375 6.875 10.3125 6.875H19.6875C20.0063 6.875 20.2734 6.98344 20.4891 7.20031C20.7047 7.41721 20.8125 7.68596 20.8125 8.00656C20.8125 8.32719 20.7047 8.59375 20.4891 8.80625C20.2734 9.01875 20.0063 9.125 19.6875 9.125H10.3125ZM17.25 15.5C16.9313 15.5 16.6641 15.3916 16.4484 15.1747C16.2328 14.9578 16.125 14.689 16.125 14.3684C16.125 14.0478 16.2328 13.7813 16.4484 13.5688C16.6641 13.3563 16.9313 13.25 17.25 13.25H22.5C24 13.25 25.25 12.75 26.25 11.75C27.25 10.75 27.75 9.5 27.75 8C27.75 6.5 27.25 5.25 26.25 4.25C25.25 3.25 24 2.75 22.5 2.75H17.25C16.9313 2.75 16.6641 2.64156 16.4484 2.42469C16.2328 2.20779 16.125 1.93904 16.125 1.61844C16.125 1.29781 16.2328 1.03125 16.4484 0.81875C16.6641 0.60625 16.9313 0.5 17.25 0.5H22.5C24.625 0.5 26.4063 1.21875 27.8438 2.65625C29.2813 4.09375 30 5.875 30 8C30 10.125 29.2813 11.9063 27.8438 13.3438C26.4063 14.7813 24.625 15.5 22.5 15.5H17.25Z" />
    </svg>
  );
});
export default QuickLinkIcon;
