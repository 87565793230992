import { API_CONSTANTS } from "./envApiConstants";

export const BUDGET_SECTION_CONSTANTS = {
  BUDGET_PREPARATION: {
    MONTHS: "June",
    IMG_URL: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-image-14.png`,
    IMG_ALT: "",
    TITLE: "Budget Preparation Timeline:",
    DESCRIPTION: `State agencies embark on budget preparation a year and a half in advance, commencing during the summer preceding the upcoming fiscal year. 
    
    In June, the Governor's Division of Financial Management (DFM) and the Legislative Services Office's Division of Budget and Policy Analysis (LSO) distribute the comprehensive "Budget Development Manual" to agencies. This manual serves as a guide, outlining budget submission protocols and offering insights into critical elements such as employee benefit costs, operating expenses, medical expenses, and Changes in Employee Compensation (CEC).`,
  },
  ANALYSIS_PHASE: {
    MONTHS: "July - August",
    IMG_URL: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-image-13.png`,
    IMG_ALT: "",
    TITLE: "Budget Analysis Phase:",
    DESCRIPTION:
      "During July and August, agencies work closely with DFM and LSO budget analysts to refine budget proposals. On September 1, agencies submit budgets to DFM and LSO, and from October to November, state budget analysts review, refine, and develop alternative options.",
  },
  GOVERNORS_RECOMMENDATION: {
    MONTHS: "January",
    IMG_URL: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-image-10.png`,
    IMG_ALT: "",
    TITLE: "Governor’s Recommendation:",
    DESCRIPTION:
      "By early December, the Governor finalizes the recommendation budget, and the Executive Budget is published. During the first week of the legislative session in January, the Governor presents his recommendations to the legislature.",
  },
  APPROPRIATIONS_PROCESS: {
    MONTHS: "January - April",
    IMG_URL: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-appropriation-process.png`,
    IMG_ALT: "",
    TITLE: "Appropriations Process:",
    DESCRIPTION: `The Joint Finance-Appropriations Committee (JFAC) of the Idaho State Legislature conducts hearings, receiving agency testimonies on their budget requests and the Governor's recommendations.
    
    Following these deliberations, JFAC initiates the budget-setting process, crafting an appropriation bill (legislation) that must pass through both the House and Senate. Successful passage and the Governor's signature signify approval, while any setbacks restart the process, either through non-passage or gubernatorial veto.`,
  },
  BUDGET_EXECUTION: {
    MONTHS: "July",
    IMG_URL: `${API_CONSTANTS.imageBucket}/ui-images/sco-ui-budget-execution.png`,
    IMG_ALT: "",
    TITLE: "Budget Execution:",
    DESCRIPTION: `Upon successful passage and gubernatorial approval, agencies’ budgets are set for the upcoming fiscal year, enabling them to commence strategic planning. 
    
    On July 1, the approved budget comes into effect, marking the initiation of the new fiscal year.`,
  },
};
