import { FC, memo } from "react";

const HighwayDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.94585 4.41475C8.82676 4.81275 7.57438 11.6278 7.57438 11.8778C7.57438 11.9441 6.1426 11.9981 4.39266 11.9981H1.21094V14.6648V17.3314H2.42302H3.6351V15.9981V14.6648H14.5439H25.4526V15.9981V17.3314H26.6647H27.8768V14.6648V11.9981H24.7166H21.5564L21.2321 10.2481C21.0537 9.28542 20.7315 7.52308 20.5161 6.33142L20.1243 4.16475L17.9401 4.06675L15.7559 3.96875V7.98342V11.9981H14.5439H13.3318V7.99808V3.99808H11.2012C9.76522 3.99808 9.02978 4.13408 8.94585 4.41475ZM5.62201 22.2481C5.14597 24.9521 4.69416 27.3524 4.6178 27.5814C4.51114 27.9027 5.49262 27.9981 8.90555 27.9981H13.3318V22.6648V17.3314H9.90945H6.48714L5.62201 22.2481ZM15.7559 22.6648V27.9981H20.1822C23.5951 27.9981 24.5766 27.9027 24.4699 27.5814C24.3935 27.3524 23.9417 24.9521 23.4657 22.2481L22.6006 17.3314H19.1783H15.7559V22.6648Z"
        fill="black"
      />
    </svg>
  );
});
export default HighwayDistrictIcon;
