import { FC, memo } from "react";

const WaterDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1566 1.91503C9.44706 6.66703 6.58048 11.6564 5.4487 15.3317C3.87208 20.4517 5.98596 25.939 10.4534 28.3227C12.6251 29.4817 16.4632 29.4817 18.635 28.3227C23.1024 25.939 25.2163 20.4517 23.6396 15.3317C22.7866 12.5617 21.2906 9.62536 18.9913 6.20836C17.0256 3.28736 14.789 0.332031 14.5442 0.332031C14.4608 0.332031 13.8366 1.04436 13.1566 1.91503Z"
        fill="black"
      />
    </svg>
  );
});
export default WaterDistrictIcon;
