import { FC, memo } from "react";

const FileShieldIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M21.2177 12.4286V5.28457C21.2178 5.19436 21.1982 5.10502 21.16 5.02167C21.1219 4.93832 21.0659 4.86261 20.9953 4.79886L17.0175 1.20114C16.8754 1.07251 16.6827 1.00016 16.4817 1H1.75816C1.55709 1 1.36424 1.07224 1.22206 1.20084C1.07988 1.32944 1 1.50385 1 1.68571V23.1714C1 23.3533 1.07988 23.5277 1.22206 23.6563C1.36424 23.7849 1.55709 23.8571 1.75816 23.8571H12.3725M6.05443 10.1429H16.1633M6.05443 5.57143H11.1089M6.05443 14.7143H9.84525"
        stroke="#565654"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1641 1V4.88571C16.1641 5.06758 16.2439 5.24199 16.3861 5.37059C16.5283 5.49918 16.7211 5.57143 16.9222 5.57143H21.2185M21.2084 16L24.4382 16.7417C24.7743 16.8194 25.0106 17.096 25.0005 17.4091C24.7831 23.9897 20.5867 25 20.5867 25C20.5867 25 16.3902 23.9897 16.1729 17.4091C16.169 17.2567 16.2225 17.1076 16.3247 16.9863C16.4269 16.865 16.5717 16.7788 16.7352 16.7417L19.965 16C20.3731 15.9063 20.8002 15.9063 21.2084 16Z"
        stroke="#565654"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
});
export default FileShieldIcon;
