import { FC } from "react";
import { Link } from "react-router-dom";
import { BasicEntity } from "../../../../types/interfaces/entityInterfaces";
import QuickLinkCard, {
  QuickLinkCardProps,
} from "../../../../components/quickLinkCard/QuickLinkCard";
import CountyItem from "../../../countiesLandingPage/countiesComponents/countyItem/CountyItem";
import "./megaSearchMenu.scss";

type MegaSearchMenuProps = {
  listData?: BasicEntity[];
  showMegaSearchMenu: boolean;
  setShowMegaSearchMenu: React.Dispatch<React.SetStateAction<boolean>>;
  quickLinkCards: QuickLinkCardProps[];
  title: string;
  simpleLinks?: SimpleLinkProps[];
};

type SimpleLinkProps = {
  title: string;
  url: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  externalUrl: boolean;
};

const MegaSearchMenu: FC<MegaSearchMenuProps> = ({
  listData = [],
  showMegaSearchMenu,
  title,
  quickLinkCards,
  setShowMegaSearchMenu,
  simpleLinks = [],
}) => {
  const SimpleLink: FC<SimpleLinkProps> = ({
    title,
    url,
    Icon,
    externalUrl,
  }) => {
    return (
      <div className="simple-link">
        <Icon className="svg-icon" />
        {externalUrl ? (
          <a className="link" href={url} target="_blank">
            {title}
          </a>
        ) : (
          <Link className="link" to={url}>
            {title}
          </Link>
        )}
      </div>
    );
  };

  return (
    <div
      className={
        showMegaSearchMenu
          ? "mega-search-container"
          : "mega-search-container hide"
      }
    >
      <div className="menu-header">
        <h6 className="menu-header-title">{title}</h6>
      </div>
      <div className="menu-body">
        {quickLinkCards.map((card, index) => {
          return (
            <QuickLinkCard
              key={index}
              title={card.title}
              imgLink={card.imgLink}
              description={card.description}
              buttonNavigatorLink={card.buttonNavigatorLink}
              navTag={card.navTag}
              positionImageTop={
                card.positionImageTop ? card.positionImageTop : false
              }
            />
          );
        })}
        {listData.length !== 0 && (
          <div className="item-list">
            {listData?.map((item) => {
              return (
                <CountyItem
                  onClickAction={() => setShowMegaSearchMenu(false)}
                  key={item.id}
                  id={item.id}
                  name={item.name}
                />
              );
            })}
          </div>
        )}
        {simpleLinks?.length !== 0 && (
          <div className="simple-links-container">
            {simpleLinks?.map((link) => {
              return (
                <SimpleLink
                  key={link.title}
                  title={link.title}
                  url={link.url}
                  Icon={link.Icon}
                  externalUrl={link.externalUrl}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MegaSearchMenu;
