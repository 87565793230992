import {
  Dispatch,
  SetStateAction,
  useState,
  FC,
  useRef,
  useEffect,
} from "react";
import { Close } from "styled-icons/material-rounded";
import { EntityFilter } from "../../types/interfaces/entityInterfaces";
import { Search } from "styled-icons/fluentui-system-filled";
import "./searchComponent.scss";

interface SearchComponentProps {
  filterText: string;
  setFilterText: Dispatch<SetStateAction<string>>;
  entitiesData?: EntityFilter[];
  isEntitiesFiltered?: boolean;
}

const SearchComponent: FC<SearchComponentProps> = ({
  filterText,
  setFilterText,
  isEntitiesFiltered = true,
  entitiesData,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const catMenuRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  useEffect(() => {
    const handleMouseDown = (e: MouseEvent) => {
      if (
        catMenuRef.current &&
        !catMenuRef.current.contains(e.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const handleFilterSearch = (): EntityFilter[] | undefined => {
    return isEntitiesFiltered
      ? entitiesData?.filter((item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase())
        )
      : undefined;
  };

  const handleClick = (item: EntityFilter) => {
    if (!isEntitiesFiltered && item.hasThisEntity === "FALSE") return;
    setFilterText(item.name);
    setDropdownOpen(false);
  };

  return (
    <div className="filter-container" ref={catMenuRef}>
      <div onClick={toggleDropdown} className="selected-container">
        <Search width={25} />
        <input
          placeholder="Search..."
          value={filterText}
          onChange={(event) => setFilterText(event.target.value)}
          className="input"
        />
        {filterText && (
          <Close onClick={() => setFilterText("")} color="gray" size={25} />
        )}
      </div>
      {dropdownOpen && (
        <div id="wrap" className="select-box">
          <div className="input-box"></div>
          <div className="list-container">
            {handleFilterSearch()?.map((item, index) => (
              <div
                key={`${item.name}-${index}`}
                onClick={() => handleClick(item)}
                className={`selects-container${
                  isEntitiesFiltered && item.hasThisEntity === "FALSE"
                    ? "-no-entity"
                    : ""
                }`}
              >
                {item.Icon && (
                  <>
                    <p>{item.Icon.displayName}</p>
                    <item.Icon className="icon-entity-selects" />
                  </>
                )}
                <p
                  className={`select-text${
                    isEntitiesFiltered && item.hasThisEntity === "FALSE"
                      ? "-no-entity"
                      : ""
                  }`}
                >
                  {item.name}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
