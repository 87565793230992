import { FC, memo } from "react";

const InfrastructureDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.01534 6.69222L3.63672 10.6762V18.5199V26.3639L4.38063 27.1819L5.12425 28.0002H14.5455H23.9667L24.7103 27.1819L25.4542 26.3639V18.5199V10.6759L20.0059 6.67122C17.0093 4.46889 14.5209 2.67622 14.4758 2.68755C14.4309 2.69922 11.9737 4.50122 9.01534 6.69222ZM13.0304 11.4916C13.0304 12.5302 11.6853 13.3866 10.6971 12.9769C10.138 12.7452 9.92561 12.8876 9.37987 13.8589C8.74777 14.9842 8.74989 16.0002 9.38472 16.0002C9.55653 16.0002 9.69714 16.6002 9.69714 17.3336C9.69714 18.0669 9.55653 18.6669 9.38472 18.6669C8.74989 18.6669 8.74777 19.6829 9.37987 20.8082C9.92561 21.7796 10.138 21.9219 10.6971 21.6902C11.6953 21.2762 13.0304 22.1366 13.0304 23.1936C13.0304 23.9916 13.1076 24.0312 14.4697 23.9352C15.6557 23.8516 15.9248 23.7126 15.9985 23.1466C16.1221 22.1959 17.5405 21.3326 18.3938 21.6882C18.9535 21.9219 19.1647 21.7806 19.7111 20.8082C20.3432 19.6829 20.341 18.6669 19.7062 18.6669C19.1962 18.6669 19.3271 16.0999 19.8483 15.8796C20.4607 15.6212 20.4207 15.1219 19.6817 13.8066C19.1629 12.8832 18.9505 12.7466 18.3938 12.9789C17.466 13.3656 16.0606 12.4316 16.0606 11.4282C16.0606 10.7369 15.9215 10.6669 14.5455 10.6669C13.1355 10.6669 13.0304 10.7242 13.0304 11.4916ZM12.8652 15.4852C12.3961 16.0009 12.1213 16.6842 12.1213 17.3336C12.1213 17.9829 12.3961 18.6662 12.8652 19.1819C13.334 19.6979 13.9552 20.0002 14.5455 20.0002C16.6639 20.0002 17.729 17.1386 16.2257 15.4852C15.7569 14.9692 15.1358 14.6669 14.5455 14.6669C13.9552 14.6669 13.334 14.9692 12.8652 15.4852Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default InfrastructureDistrictIcon;
