import { FC, memo } from "react";

const GraphIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="37"
      style={{ display: "flex" }}
      height="36"
      viewBox="0 0 37 36"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Graph">
        <path
          id="Vector"
          d="M6.125 16.125V28.875H12.875V16.125H6.125ZM15.125 7.125V28.875H21.875V7.125H15.125ZM24.125 19.125V28.875H30.875V19.125H24.125ZM30.875 31.125H6.125C5.50625 31.125 4.97656 30.9047 4.53594 30.4641C4.09531 30.0234 3.875 29.4938 3.875 28.875V16.125C3.875 15.5063 4.09531 14.9766 4.53594 14.5359C4.97656 14.0953 5.50625 13.875 6.125 13.875H12.875V7.125C12.875 6.50625 13.0953 5.97656 13.5359 5.53594C13.9766 5.09531 14.5063 4.875 15.125 4.875H21.875C22.4938 4.875 23.0234 5.09531 23.4641 5.53594C23.9047 5.97656 24.125 6.50625 24.125 7.125V16.875H30.875C31.4938 16.875 32.0234 17.0953 32.4641 17.5359C32.9047 17.9766 33.125 18.5063 33.125 19.125V28.875C33.125 29.4938 32.9047 30.0234 32.4641 30.4641C32.0234 30.9047 31.4938 31.125 30.875 31.125Z"
        />
      </g>
    </svg>
  );
});
export default GraphIcon;
