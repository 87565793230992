import { requestPageReports } from "../api/searchType.api"

export enum openGovPages {
    StateLanding = 'State Landing',
    Vendor = 'Vendor',
}

export const getOpenGovReportsByPage = async (sectionParam: openGovPages) => {
    try {
        const data = await requestPageReports(sectionParam)
        return data
      } catch (error) {
        console.error('reload page')
      }
}
