import { FC, memo } from "react";

const TVTranslatorDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.4463 0H2.64463C1.19008 0 0 1.6 0 3.55556V24.8889C0 26.8444 1.19008 28.4444 2.64463 28.4444H9.2562V32H19.8347V28.4444H26.4463C27.9008 28.4444 29.0777 26.8444 29.0777 24.8889L29.0909 3.55556C29.0909 1.6 27.9008 0 26.4463 0ZM26.4463 24.8889H2.64463V3.55556H26.4463V24.8889Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default TVTranslatorDistrictIcon;
