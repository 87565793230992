import React, { forwardRef } from "react";
import "./aboutSection.scss";

type AboutSectionProps = {
  imageUrl: string;
  imageAltText: string;
  title: string;
  description: string;
  dataInfo?: string;
};

const AboutSection = forwardRef<HTMLDivElement, AboutSectionProps>(
  ({ imageUrl, imageAltText, title, description, dataInfo }, ref) => {
    return (
      <div className="about-section" ref={ref}>
        <div className="description-container">
          <h4 className="title">{title}</h4>
          <p className="body-medium description">{description}</p>
          <p className="body-medium data-info">{dataInfo}</p>
        </div>
        <div>
          <img src={imageUrl} className="image" alt={imageAltText} />
        </div>
      </div>
    );
  }
);

export default AboutSection;
