import { FC, memo } from "react";

const AuditoriumDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.21094 10.6667V17.3333H2.42302H3.6351V12V6.66667H13.9378H24.2405V12V17.3333H25.4526H26.6647V10.6667V4H13.9378H1.21094V10.6667ZM6.80319 16.8183C5.66837 18.0663 5.82534 18.6667 7.28651 18.6667C7.96133 18.6667 8.91585 18.8957 9.40765 19.1753C10.2088 19.631 10.3943 19.631 11.1955 19.1753C11.6873 18.8957 12.3691 18.6667 12.7106 18.6667C13.5548 18.6667 13.4987 17.82 12.5879 16.8183C11.9382 16.1033 11.5727 16 9.69552 16C7.81831 16 7.45286 16.1033 6.80319 16.8183ZM15.2878 16.8183C14.153 18.0663 14.3099 18.6667 15.7711 18.6667C16.4459 18.6667 17.4004 18.8957 17.8922 19.1753C18.6934 19.631 18.8789 19.631 19.6801 19.1753C20.1719 18.8957 20.8537 18.6667 21.1952 18.6667C22.0394 18.6667 21.9833 17.82 21.0724 16.8183C20.4228 16.1033 20.0573 16 18.1801 16C16.3029 16 15.9374 16.1033 15.2878 16.8183ZM3.16694 22.1517C2.50847 22.8757 2.42302 23.259 2.42302 25.485V28H6.05927H9.69552V25.485C9.69552 23.259 9.61007 22.8757 8.9516 22.1517C8.30193 21.4367 7.93648 21.3333 6.05927 21.3333C4.18206 21.3333 3.81661 21.4367 3.16694 22.1517ZM11.6515 22.1517C10.9931 22.8757 10.9076 23.259 10.9076 25.485V28H14.5439H18.1801V25.485C18.1801 23.259 18.0947 22.8757 17.4362 22.1517C16.7865 21.4367 16.4211 21.3333 14.5439 21.3333C12.6666 21.3333 12.3012 21.4367 11.6515 22.1517ZM20.1361 22.1517C19.4776 22.8757 19.3922 23.259 19.3922 25.485V28H23.0284H26.6647V25.485C26.6647 23.259 26.5792 22.8757 25.9208 22.1517C25.2711 21.4367 24.9057 21.3333 23.0284 21.3333C21.1512 21.3333 20.7858 21.4367 20.1361 22.1517Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default AuditoriumDistrictIcon;
