import { FC, memo } from "react";

const WasteDisposalIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1243 6.00836C9.75956 6.89903 6.60966 7.85336 6.12422 8.12936C5.14334 8.68736 4.37215 9.81503 4.07216 11.1307L3.87398 11.9987L7.77053 12.0014L11.6668 12.0044L17.1969 9.29136C20.2383 7.79936 22.727 6.40636 22.727 6.19603C22.727 5.98569 22.5146 5.48036 22.2552 5.07303C21.5677 3.99269 20.2201 4.13069 13.1243 6.00836ZM3.63672 21.3144V27.9987H7.87901H12.1213V23.9987V19.9987H15.7576C19.1917 19.9987 19.3938 20.0357 19.3938 20.6654C19.3938 21.2884 19.5959 21.332 22.4664 21.332H25.5391L25.3645 19.473C25.1709 17.4087 24.6385 16.3 23.4185 15.42C22.6922 14.896 21.574 14.821 13.1201 14.731L3.63672 14.6304V21.3144ZM21.5356 24.3C20.4171 26.028 20.8492 27.6654 22.424 27.6654C23.4067 27.6654 23.9391 27.1037 23.9391 26.0667C23.9391 25.2384 22.8828 23.332 22.424 23.332C22.2801 23.332 21.8804 23.7677 21.5356 24.3Z"
        fill="black"
      />
    </svg>
  );
});

export default WasteDisposalIcon;
