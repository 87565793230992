import { StyledIcon } from "styled-icons/types";
import "./infoBubble.scss";

type InfoBubbleProps = {
  titleImage: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>> | StyledIcon;
  infoAmount: string;
  background?: string;
  infoDetailText?: string;
  bottomHeader?: string;
  bottomSubHeader?: string;
  whiteBubbleTitleColor?: boolean;
};

const InfoBubble = (props: InfoBubbleProps) => {
  const {
    titleImage,
    infoAmount,
    Icon,
    background,
    infoDetailText,
    bottomHeader,
    bottomSubHeader,
    whiteBubbleTitleColor = false,
  } = props;

  return (
    <div className="info-bubble-container">
      <div className={`info-bubble-title-container ${whiteBubbleTitleColor ? 'invert' : null}`}>
        <img
          src={titleImage}
          alt="bubble title"
          className="info-bubble-title-image"
        />
      </div>
      <div className={background ? " info-bubble-button-landing-page" : ""}>
        <div
          className={
            background
              ? "info-bubble-button info-bubble-button-gradient"
              : "info-bubble-button info-bubble-button-solid"
          }
        >
          <>
            <Icon className="info-bubble-icon" />
            <h6 className="info-bubble-amount-text">
              {infoAmount === "" ? "Loading..." : infoAmount}
              {infoDetailText ? "\n" + infoDetailText : ""}
            </h6>
          </>
        </div>
      </div>
      <div
        className={
          background
            ? "info-bubbles-bottom-headers info-bubble-text-dark"
            : "info-bubbles-bottom-headers info-bubble-text-light"
        }
      >
        <h6 className="info-bubbles-bottom-header">{bottomHeader}</h6>
        <p className="body-medium info-bubbles-bottom-subheader">
          {bottomSubHeader}
        </p>
      </div>
    </div>
  );
};

export default InfoBubble;
