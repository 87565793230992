import { FC, memo } from "react";

const DollarSignIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="20"
      height="37"
      viewBox="0 0 20 37"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1622_22777)">
        <path
          d="M9.68959 15.2446C5.67584 14.1958 4.38507 13.1113 4.38507 11.4224C4.38507 9.48464 6.17092 8.13353 9.15913 8.13353C12.3065 8.13353 13.4735 9.64464 13.5796 11.8669H17.4872C17.3635 8.80908 15.5069 6.0002 11.8114 5.09353V1.2002H6.50688V5.04019C3.07662 5.78686 0.318271 8.02686 0.318271 11.458C0.318271 15.5646 3.69548 17.6091 8.62868 18.8002C13.0491 19.8669 13.9332 21.4313 13.9332 23.0846C13.9332 24.3113 13.0668 26.2669 9.15913 26.2669C5.5167 26.2669 4.08448 24.6313 3.88998 22.5335H0C0.212181 26.4269 3.11198 28.6135 6.50688 29.3424V33.2002H11.8114V29.378C15.2593 28.7202 18 26.7113 18 23.0669C18 18.018 13.7033 16.2935 9.68959 15.2446Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1622_22777"
          x="-2"
          y="0.200195"
          width="22"
          height="36"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1622_22777"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1622_22777"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
});
export default DollarSignIcon;
