import { useLayoutEffect, useRef } from "react";
import { API_CONSTANTS } from "../../types/constants";
import {
  ArrowRightIcon,
  BalanceScaleIcon,
  FileArrowIcon,
  FileShieldIcon,
  FolderIcon,
} from "../../styles/icons";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { GradientButton } from "../../components";
import CommonlyRequested from "./publicRecordComponents/CommonlyRequested";
import "./publicRecordRequestPage.scss";

const PublicRecordRequestPage = () => {
  const publicRecordRef = useRef<HTMLDivElement>(null);
  const commonReqRef = useRef<HTMLDivElement>(null);
  const publicRecordPolicyRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="landing-page-container landing-page-picture-background public-records-page">
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container">
          <span className="landing-page-hero-data">
            <span className="landing-page-explore-info-text">
              <h2 className="landing-page-hero-header">
                Explore Public Records
              </h2>
              <h6 className="landing-page-hero-subheader">
                The State Controller's office is dedicated to transparency and
                meeting the requests of the citizens by providing public records
                in compliance with state law.
              </h6>
            </span>
            <br />
            <br />
            <div className="pr-content-tags-container">
              <div className="content-tags">
                <button className="content-tag" onClick={() => publicRecordRef.current?.scrollIntoView({ behavior: "smooth" })}>
                  <BalanceScaleIcon className="icon" />
                  <p className="body-large">Public Record Law</p>
                </button>
                <button className="content-tag" onClick={() => commonReqRef.current?.scrollIntoView({ behavior: "smooth" })}>
                  <FolderIcon className="icon" />
                  <p className="body-large">Commonly Requested Records</p>
                </button>
                <button className="content-tag" onClick={() => publicRecordPolicyRef.current?.scrollIntoView({ behavior: "smooth" })}>
                  <FileShieldIcon className="icon" />
                  <p className="body-large">Public Records Policy</p>
                </button>
              </div>
            </div>
          </span>
        </div>
        <span className="hero-separator" />
        <div className="about-section textured-background" ref={publicRecordRef}>
          <div className="description-container">
            <h2 className="title">Public Record Request</h2>
            <p className="body-large description">
              {" "}
              {`The Office of the State Controller provides public access to records such as state transactions, salaries of state employees, financial reports for Idaho and budget and audit documents of local government entities. \n \n To respect privacy and legal limits, the office does not keep records of personal background information or specific details such as contracts, invoices, and transactions pertaining to local entities. For this information, please reach out directly to those organizations.`}
            </p>
            <a
              href={"https://legislature.idaho.gov/statutesrules/idstat/title74/t74ch1/"}
              target="_blank"
              className="link"
            >
              <p>Idaho Public Record Law</p>
              <ArrowRightIcon />
            </a>
          </div>
          <div>
            <img
              src={`${API_CONSTANTS.imageBucket}/ui-images/sco-ui-public-records.png`}
              className="image"
              alt={"public records in a file cabinet"}
            />
          </div>
        </div>
        <CommonlyRequested ref={commonReqRef}/>
        <div className="additional-records-container textured-background">
          <h4>Access Additional Records</h4>
          <p className="body-large">
            Submit a public record request to the Idaho State Controller's
            Office below.
          </p>
          <GradientButton
            buttonText={"Submit Record Request"}
            buttonSize={"button-text-medium"}
            ButtonAction={() => {
              window.open(
                "https://stateofidaho.qualtrics.com/jfe/form/SV_4YmysMHiB7PTcKG",
                "_blank"
              );
            }}
          />
        </div>
        <div className="about-section controller" ref={publicRecordPolicyRef}>
          <img
            src={`${API_CONSTANTS.imageBucket}/ui-images/sco-ui-controller.jpeg`}
            alt="Idaho State Controller"
            className="image"
          />
          <div className="description-container">
            <h4 className="title">State Controller’s Policy</h4>
            <p className="body-medium description">
              State Controller Brandon D. Woolf upholds the public's right to
              access government information. As per the Idaho Public Records Act
              (Idaho Code §74-101 through §74-126), our office has implemented a
              Public Records Request Policy. This policy aims to inform the
              public about their rights and obligations under the law.
              Additionally, the Office of the State Controller offers public
              access to various financial records, including state spending and
              payroll information. Many of these records are already available
              on the Transparent Idaho website.
            </p>
            <a
              href={
                "https://sco-loctrans-images.s3.us-gov-west-1.amazonaws.com/pdfs/Public+Records+Request+Policy+-+Updated.pdf"
              }
              target="_blank"
              className="link"
            >
              <p>See State Controller's Public Record Policy</p>
            </a>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};
export default PublicRecordRequestPage;
