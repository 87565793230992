import {
  EntityFilter,
  EntityFinancialFilter,
} from "../../types/interfaces/entityInterfaces";
import { ENTITY_TYPE_IDS } from "../../types/constants/entityTypeIds";
import {
  requestEntitiesByTypeId,
  requestFinancialsByEntityType,
} from "../../api/searchType.api";
import CityIcon from "../../styles/icons/CityIcon";

export const getCities = async () => {
  const data = await requestEntitiesByTypeId(String(ENTITY_TYPE_IDS.CITY));
  //eslint-disable-next-line
  const entitiesData: EntityFilter[] = data.map((entity: any) => {
    return {
      id: entity.EntityID,
      name: entity.EntityName,
      type: ENTITY_TYPE_IDS.CITY,
      icon: CityIcon,
    };
  });
  return entitiesData;
};

export const getCitiesFinancial = async (fiscalYear: string) => {
  const data = await requestFinancialsByEntityType(
    String(ENTITY_TYPE_IDS.CITY),
    fiscalYear
  );
  //eslint-disable-next-line
  const entityData: EntityFinancialFilter[] = !data
    ? []
    : data.map((entity: any) => {
        return {
          id: entity.EntityID,
          name: entity.EntityName,
          type: ENTITY_TYPE_IDS.CITY,
          icon: CityIcon,
          fiscalYear: entity.FiscalYear,
          revenueActual: entity.RevenueActual,
          expenseActual: entity.ExpenseActual,
          revenueBudget: entity.RevenueBudget,
          expenseBudget: entity.ExpenseBudget,
          reportDocs: entity.ReportDocs,
        };
      });
  return entityData;
};
