import { FC, memo } from "react";

const IrrigationDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.06203 6.0013V9.33464H7.27411H8.4862V6.0013V2.66797H7.27411H6.06203V6.0013ZM15.7587 6.66797V10.668H16.9708H18.1829V6.66797V2.66797H16.9708H15.7587V6.66797ZM10.9104 10.0013V14.668H12.1224H13.3345V10.0013V5.33464H12.1224H10.9104V10.0013ZM20.607 10.668V14.668H21.8191H23.0312V10.668V6.66797H21.8191H20.607V10.668ZM2.42578 17.581C2.42578 17.7456 2.64547 18.695 2.91425 19.6906C4.43087 25.3146 6.95564 27.5546 10.2901 26.2356C11.0358 25.9406 11.7046 25.764 11.7764 25.843C11.8482 25.922 11.9731 26.74 12.0534 27.6606L12.1994 29.3346H13.373C14.4736 29.3346 14.5466 29.2726 14.5466 28.34C14.5466 27.7126 14.8721 26.9446 15.4272 26.261L16.3078 25.1766L17.5138 25.9223C18.9131 26.7873 19.7101 26.853 20.8494 26.1963C22.5349 25.225 24.5333 22.1746 25.2902 19.418C25.4957 18.6703 25.4854 18.668 21.9812 18.668C17.9868 18.668 16.5402 19.1173 16.0599 20.507C15.5984 21.8423 15.6805 21.9793 16.7414 21.6446C17.282 21.4743 18.1344 21.3446 18.6353 21.3566C19.3416 21.3736 19.1731 21.489 17.8871 21.869C16.9747 22.1386 15.6639 22.723 14.9748 23.1673L13.7215 23.9753L12.3737 22.5546C11.6322 21.7733 10.5567 20.9696 9.98312 20.7683C9.40981 20.5673 9.16557 20.3873 9.44011 20.3686C9.71495 20.35 10.7186 20.7853 11.6703 21.336L13.4009 22.3373L13.2024 21.4193C12.9536 20.2673 12.1782 19.0773 11.0979 18.1886C10.3749 17.594 9.73434 17.4866 6.34414 17.3916C4.15664 17.3303 2.42578 17.414 2.42578 17.581Z"
          fill="black"
        />
      </svg>
    );
  }
);

export default IrrigationDistrictIcon;
