import { FC, memo } from "react";

const DollarBrokenIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7.66602V21.666M17.5 11.7493C17.5 10.1393 15.9332 8.83268 14 8.83268C12.0668 8.83268 10.5 10.1393 10.5 11.7493C10.5 13.3593 12.0668 14.666 14 14.666C15.9332 14.666 17.5 15.9727 17.5 17.5827C17.5 19.1927 15.9332 20.4993 14 20.4993C12.0668 20.4993 10.5 19.1927 10.5 17.5827"
        stroke="#404E65"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.16536 4.56053C9.93814 3.53491 11.9506 2.99637 13.9987 2.99953C20.4422 2.99953 25.6654 8.22269 25.6654 14.6662C25.6654 21.1097 20.4422 26.3329 13.9987 26.3329C7.5552 26.3329 2.33203 21.1097 2.33203 14.6662C2.33203 12.5417 2.9002 10.5479 3.89303 8.83286"
        stroke="#404E65"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
});

export default DollarBrokenIcon;
