import { FC, memo } from "react";

const CityIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.1332 8.00008H21.1554V4.00008C21.1554 3.26275 20.5992 2.66675 19.911 2.66675H9.95544C9.26726 2.66675 8.71099 3.26275 8.71099 4.00008V13.3334H3.73321C3.04504 13.3334 2.48877 13.9294 2.48877 14.6667V25.3334C2.48877 26.0707 3.04504 26.6667 3.73321 26.6667H8.71099H14.9332H21.1554H26.1332C26.8214 26.6667 27.3777 26.0707 27.3777 25.3334V9.33341C27.3777 8.59608 26.8214 8.00008 26.1332 8.00008ZM7.46655 24.0001H4.97766V21.3334H7.46655V24.0001ZM7.46655 18.6667H4.97766V16.0001H7.46655V18.6667ZM13.6888 24.0001H11.1999V21.3334H13.6888V24.0001ZM13.6888 18.6667H11.1999V16.0001H13.6888V18.6667ZM13.6888 13.3334H11.1999V10.6667H13.6888V13.3334ZM13.6888 8.00008H11.1999V5.33341H13.6888V8.00008ZM18.6665 24.0001H16.1777V21.3334H18.6665V24.0001ZM18.6665 18.6667H16.1777V16.0001H18.6665V18.6667ZM18.6665 13.3334H16.1777V10.6667H18.6665V13.3334ZM18.6665 8.00008H16.1777V5.33341H18.6665V8.00008ZM24.8888 24.0001H22.3999V21.3334H24.8888V24.0001ZM24.8888 18.6667H22.3999V16.0001H24.8888V18.6667ZM24.8888 13.3334H22.3999V10.6667H24.8888V13.3334Z"
        fill="black"
      />
    </svg>
  );
});
export default CityIcon;
