import { FC, memo } from "react";

const HospitalDistrictIcon: FC<React.SVGProps<SVGSVGElement>> = memo(
  (props) => {
    return (
      <svg
        {...props}
        width="30"
        height="32"
        viewBox="0 0 30 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2126 4.16667C11.1787 5.304 11.0914 5.33333 8.74844 5.33333C5.73883 5.33333 4.44615 5.87933 3.98101 7.34733C3.44709 9.032 3.51315 26.0283 4.05889 27.4657C4.71735 29.199 5.47218 29.3333 14.5458 29.3333C23.6195 29.3333 24.3743 29.199 25.0328 27.4657C25.6422 25.8607 25.6422 8.806 25.0328 7.201C24.4928 5.77967 23.3592 5.33333 20.2884 5.33333C18.0081 5.33333 17.9088 5.29933 16.8791 4.16667C16.0037 3.20367 15.5964 3 14.5458 3C13.4953 3 13.088 3.20367 12.2126 4.16667ZM13.4592 5.78767C13.3637 6.06133 13.3307 6.63333 13.3856 7.059C13.4683 7.70067 13.6668 7.83333 14.5458 7.83333C15.5558 7.83333 15.6064 7.77767 15.6064 6.66667C15.6064 5.59367 15.5273 5.49167 14.6195 5.39533C13.9577 5.32533 13.5756 5.45433 13.4592 5.78767ZM13.3337 15.3333V17.3333H11.5156H9.6975V18.6667V20H11.5156H13.3337V22V24H14.5458H15.7579V22V20H17.576H19.3942V18.6667V17.3333H17.576H15.7579V15.3333V13.3333H14.5458H13.3337V15.3333Z"
          fill="black"
        />
      </svg>
    );
  }
);
export default HospitalDistrictIcon;
