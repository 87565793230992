import { FC, memo } from "react";

const ThreeDotCircleIcon: FC<React.SVGProps<SVGSVGElement>> = memo((props) => {
  return (
    <svg
      {...props}
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_884_35309)">
        <path
          d="M24.5 4C35.546 4 44.5 12.954 44.5 24C44.5 35.046 35.546 44 24.5 44C13.454 44 4.5 35.046 4.5 24C4.5 12.954 13.454 4 24.5 4ZM24.5 8C20.2565 8 16.1869 9.68571 13.1863 12.6863C10.1857 15.6869 8.5 19.7565 8.5 24C8.5 28.2435 10.1857 32.3131 13.1863 35.3137C16.1869 38.3143 20.2565 40 24.5 40C28.7435 40 32.8131 38.3143 35.8137 35.3137C38.8143 32.3131 40.5 28.2435 40.5 24C40.5 19.7565 38.8143 15.6869 35.8137 12.6863C32.8131 9.68571 28.7435 8 24.5 8ZM15.5 21C16.2956 21 17.0587 21.3161 17.6213 21.8787C18.1839 22.4413 18.5 23.2044 18.5 24C18.5 24.7956 18.1839 25.5587 17.6213 26.1213C17.0587 26.6839 16.2956 27 15.5 27C14.7044 27 13.9413 26.6839 13.3787 26.1213C12.8161 25.5587 12.5 24.7956 12.5 24C12.5 23.2044 12.8161 22.4413 13.3787 21.8787C13.9413 21.3161 14.7044 21 15.5 21ZM24.5 21C25.2956 21 26.0587 21.3161 26.6213 21.8787C27.1839 22.4413 27.5 23.2044 27.5 24C27.5 24.7956 27.1839 25.5587 26.6213 26.1213C26.0587 26.6839 25.2956 27 24.5 27C23.7044 27 22.9413 26.6839 22.3787 26.1213C21.8161 25.5587 21.5 24.7956 21.5 24C21.5 23.2044 21.8161 22.4413 22.3787 21.8787C22.9413 21.3161 23.7044 21 24.5 21ZM33.5 21C34.2956 21 35.0587 21.3161 35.6213 21.8787C36.1839 22.4413 36.5 23.2044 36.5 24C36.5 24.7956 36.1839 25.5587 35.6213 26.1213C35.0587 26.6839 34.2956 27 33.5 27C32.7044 27 31.9413 26.6839 31.3787 26.1213C30.8161 25.5587 30.5 24.7956 30.5 24C30.5 23.2044 30.8161 22.4413 31.3787 21.8787C31.9413 21.3161 32.7044 21 33.5 21Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_884_35309">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default ThreeDotCircleIcon;
