import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import IdahoStateSeal from "../../styles/images/idahoStateSeal.png";
import { CountyIconBubbles } from "../../styles/icons";

import Footer from "../footer/Footer";
import Header from "../header/Header";
import {
  AboutSection,
  DownloadFileComponent,
  InfoBubble,
  LoadingBar,
  LoadingState,
  YearTagPill,
} from "../../components";
import LocalDistrictEntityFinancialTable from "./localDistrictComponents/LocalDistrictEntityFinancialTable";

import {
  NAV_TAGS,
  ENTITY_TYPE_IDS,
  API_CONSTANTS,
  ABOUT_SECTION_CONSTANTS,
} from "../../types/constants";

import { EntityFinancialFilter } from "../../types/interfaces/entityInterfaces";
import { getCountyEntitiesByEntityTypeID } from "../countyPage/countyPage.service";
import { requestFiscalYearList } from "../../api/searchType.api";

import "../landingPages.scss";
import "./localDistrictsLandingPage.scss";

const LocalDistrictsLandingPage = () => {
  const [fiscalYears, setFiscalYears] = useState<string[]>([]);
  const [urbanRenewalYear, setUrbanRenewalYear] = useState<string>("2022");

  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.InProgress
  );
  const [urbanRenewalPdfList, setUrbanRenewalPdfList] =
    useState<EntityFinancialFilter[]>();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const exploreDataRef = useRef<HTMLDivElement>(null);
  const allLocalDistrictsRef = useRef<HTMLDivElement>(null);
  const urbanRenewalRef = useRef<HTMLDivElement>(null);

  const tagToRefMap = {
    [NAV_TAGS.EXPLORE_LOCAL_DISTRICT_DATA]: exploreDataRef,
    [NAV_TAGS.LOCAL_DISTRICT_ALL_LOCAL_DISTRICT_BUDGETS]: allLocalDistrictsRef,
    [NAV_TAGS.Local_DISTRICT_URBAN_RENEWAL]: urbanRenewalRef,
  };

  useEffect(() => {
    if (state && state.tag) {
      const refToScroll = tagToRefMap[state.tag];
      refToScroll.current?.scrollIntoView({ behavior: "smooth" });
      navigate(location.pathname, { replace: true });
    }
  }, [state, navigate, location.pathname, tagToRefMap]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleLoad = async () => {
      const fiscalYear = await requestFiscalYearList();
      setFiscalYears(fiscalYear);
    };

    handleLoad();
  }, []);

  useEffect(() => {
    const getUrbanRenewalList = async () => {
      setLoadingState(LoadingState.InProgress);
      setUrbanRenewalPdfList([]);
      const res: EntityFinancialFilter[] =
        await getCountyEntitiesByEntityTypeID(
          ENTITY_TYPE_IDS.URBAN_RENEWAL,
          "0",
          urbanRenewalYear
        );

      const filteredRes = res.map((entity) => {
        if (entity.reportDocs) {
          entity.reportDocs = entity.reportDocs.filter((doc) => {
            return doc.Kind === "Annual";
          });
        }
        return entity;
      });

      setUrbanRenewalPdfList(filteredRes);
      setLoadingState(LoadingState.Finished);
    };
    getUrbanRenewalList();
  }, [urbanRenewalYear]);

  const renderUrbanRenewalReports = () => {
    const noReports = urbanRenewalPdfList?.every(
      (item) => !item.reportDocs || item.reportDocs.length === 0
    );
    if (loadingState === LoadingState.InProgress)
      return <div className="body-large no-reports-message">Loading...</div>;

    if (loadingState === LoadingState.Finished && noReports) {
      return <p className="body-large no-reports-message">Coming Soon!</p>;
    }

    return urbanRenewalPdfList?.map((item) =>
      item.reportDocs && item.reportDocs.length > 0 ? (
        <DownloadFileComponent
          key={item.name}
          url={item.reportUrl}
          fileName={item.name}
        />
      ) : null
    );
  };

  return (
    <div className="landing-page-container landing-page-picture-background">
      {
        <LoadingBar
          loadingState={loadingState}
          setLoadingState={setLoadingState}
        />
      }
      <header>
        <Header />
      </header>
      <main>
        <div className="landing-page-hero-container">
          <span className="landing-page-hero-data">
            <div className="landing-page-hero-info">
              <img
                className="landing-page-idaho-state-seal"
                src={IdahoStateSeal}
                alt=""
              />
              <span className="landing-page-explore-info-text">
                <h2 className="landing-page-hero-header">
                  Explore Local Districts
                </h2>
                <h6 className="landing-page-hero-subheader">
                  Idaho consists of roughly 1,050 local districts providing
                  services ranging from ambulance and fire to mosquito abatement
                </h6>
              </span>
            </div>
            <div className="landing-page-info-bubbles">
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderTaxingDistrict.svg`}
                infoDetailText={"Districts"}
                Icon={CountyIconBubbles}
                infoAmount={"832"}
                bottomHeader={`
                2022 TAX COMMISSION`}
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderEntityTypes.svg`}
                infoDetailText={"Types"}
                Icon={CountyIconBubbles}
                infoAmount={"38"}
                bottomHeader={"FROM LOCAL GOVERNMENT REGISTRY"}
              />
              <InfoBubble
                titleImage={`${API_CONSTANTS.imageBucket}/dashboard-header-images/CurvedHeaderUrbanRenewal.svg`}
                Icon={CountyIconBubbles}
                infoAmount={"49"}
                infoDetailText={"Agencies"}
                bottomHeader={"FROM LOCAL GOVERNMENT REGISTRY"}
              />
            </div>
          </span>
        </div>
        <span className="hero-separator" />
        <AboutSection
          imageUrl={API_CONSTANTS.imageBucket + "/ui-images/sco-ui-image-2.png"}
          imageAltText={ABOUT_SECTION_CONSTANTS.LOCAL_DISTRICTS.IMG_ALT}
          title={ABOUT_SECTION_CONSTANTS.LOCAL_DISTRICTS.TITLE}
          description={ABOUT_SECTION_CONSTANTS.LOCAL_DISTRICTS.DESCRIPTION}
          dataInfo={ABOUT_SECTION_CONSTANTS.LOCAL_DISTRICTS.DATA_INFO}
          ref={exploreDataRef}
        />
        <div className="search-section-ld" ref={allLocalDistrictsRef}>
          <h4 className="districts-entity-search-header">
            SEARCH LOCAL DISTRICTS
          </h4>
          <p className="districts-entity-search-subheader body-large">
            {
              "Use the search feature to find local districts that service the county. You can view each district’s approved budget totals and the previous year’s actual totals. Search cities to download applicable financial documents.\n\n You can learn more about Local Districts "
            }
            <Link
              className="local-district-here-link"
              to={"/LocalDistrict"}
              state={{ tag: NAV_TAGS.EXPLORE_LOCAL_DISTRICT_DATA }}
            >
              {"here."}
            </Link>
          </p>
          <LocalDistrictEntityFinancialTable fiscalYears={fiscalYears} />
        </div>
        <div className="ld-download-pdfs-section">
          <h4 className="urban-renewal-header" ref={urbanRenewalRef}>
            URBAN RENEWAL AGENCIES
          </h4>
          <p className="urban-renewal-subheader body-large">
            {
              "Download annual reports for each Urban Renewal Agency by choosing a year and clicking on an agency. \n\nYou can learn more about Urban Renewal Agencies "
            }
            <Link
              className="local-district-here-link"
              to={"/LocalDistrict"}
              state={{ tag: NAV_TAGS.EXPLORE_LOCAL_DISTRICT_DATA }}
            >
              {"here."}
            </Link>
          </p>
          <div className="pill-container">
            {fiscalYears?.map((year) => (
              <YearTagPill
                key={year}
                selectedYear={urbanRenewalYear}
                year={year}
                setSelectedYear={setUrbanRenewalYear}
              />
            ))}
          </div>
          <div className="ld-downloads-list-container">
            {renderUrbanRenewalReports()}
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default LocalDistrictsLandingPage;
