import { Navigate, Route, Routes } from "react-router-dom";
import { URL_CONSTANTS } from "./types/constants";
import {
  PublicRecordRequestPage,
  CountiesLandingPage,
  CountyPage,
  HomePage,
  SomeThingWentWrong,
  NotFoundPage,
  CityLandingPage,
  LocalDistrictsLandingPage,
  EducationLandingPage,
  StateLandingPage,
  WorkforceSalaryLandingPage,
  StateAgencyPage,
  VendorPaymentsLandingPage,
} from "./pages";
import "./App.scss";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path={URL_CONSTANTS.COUNTY} element={<CountiesLandingPage />} />
        <Route path={URL_CONSTANTS.COUNTY + "/:id"} element={<CountyPage />} />
        <Route path={URL_CONSTANTS.CITY} element={<CityLandingPage />} />
        <Route
          path={URL_CONSTANTS.LOCAL_DISTRICT}
          element={<LocalDistrictsLandingPage />}
        />
        <Route
          path={URL_CONSTANTS.EDUCATION}
          element={<EducationLandingPage />}
        />
        <Route path={URL_CONSTANTS.STATE} element={<StateLandingPage />} />
        <Route
          path={URL_CONSTANTS.STATE_AGENCIES}
          element={<Navigate to="/state" replace />}
        />
        <Route
          path={URL_CONSTANTS.STATE_AGENCIES + "/:id"}
          element={<StateAgencyPage />}
        />
        <Route
          path={URL_CONSTANTS.WORKFORCE_SALARY}
          element={<WorkforceSalaryLandingPage />}
        />
        <Route
          path={URL_CONSTANTS.VENDOR_PAYMENTS}
          element={<VendorPaymentsLandingPage />}
        />
        <Route
          path={URL_CONSTANTS.PUBLIC_RECORD_REQUEST}
          element={<PublicRecordRequestPage />}
        />
        <Route path={URL_CONSTANTS.ERROR} element={<SomeThingWentWrong />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
